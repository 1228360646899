<template>
  <div class="m-field-new">
    <label
      v-if="label"
      :for="inputId"
      class="m-field-new__label"
      v-html="labelHtml"
    />
    <div class="m-field-new__body">
      <slot/>
    </div>
    <transition name="fade-anim">
      <span
        v-show="error"
        class="m-field-new__error"
      >
        {{ error }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MFieldNew',
  props: {
    inputId: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  computed: {
    labelHtml() {
      return this.required ? `${this.label}<span class="m-field-new__label-spark">*</span>` : this.label;
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-field-new {
    &__label {
      color: $m-text;
      cursor: pointer;
      display: block;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
</style>

<style lang="scss">
  .m-field-new {
    position: relative;
    &__label-spark {
      color: $m-light-red;
    }

    &__error {
      display: inline-block;
      width: 364px;
      position: absolute;
      margin-top: -8px;
      bottom: 100%;
      right: 0;
      padding: 7px 12px;
      border: 1px solid $m-light-gray;
      border-radius: 6px;
      font-size: 11px;
      line-height: 13px;
      color: $m-light-red;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, .1);
      background-color: #fff;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -6px;
        left: 50%;
        margin-right: -6px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #fff transparent transparent transparent;
      }
    }
  }
</style>
