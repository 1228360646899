<template>
  <div
    class="m-pagination"
  >
    <div
      v-if="controls"
      class="m-pagination__controls"
    >
      <div
        v-if="hasPrev"
        class="m-pagination__arrow m-pagination__arrow_left"
        @click="changePage(prevPage)"
      >
        <SvgIcon
          dir="right"
          data="@icons/LMSIcons/arrow_bottom.svg"
          class="m-pagination__icon"
        />
      </div>
      <div
        v-if="hasNext"
        class="m-pagination__arrow m-pagination__arrow_right"
        @click="changePage(nextPage)"
      >
        <SvgIcon
          dir="left"
          data="@icons/LMSIcons/arrow_bottom.svg"
          class="m-pagination__icon"
        />
      </div>
    </div>
    <ul class="m-pagination__list">
      <li
        v-if="!rangeIncludesPage(1)"
        class="m-pagination__item"
      >
        <div
          class="m-pagination__item"
          @click="changePage(1)"
        >
          1
        </div>
        <div class="m-pagination__item m-pagination__item_dots">...</div>
      </li>
      <li
        v-for="(page, index) in visiblePages"
        :key="index"
        :class="{ 'm-pagination__item_current': currentPage === page }"
        class="m-pagination__item"
        @click="changePage(page)"
      >
        {{ page }}
      </li>
      <li
        v-if="!rangeIncludesPage(pageCount)"
        class="m-pagination__item"
      >
        <div class="m-pagination__item m-pagination__item_dots">...</div>
        <div
          class="m-pagination__item"
          @click="changePage(pageCount)"
        >
          {{ pageCount }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MPagination',
  props: {
    maxRange: {
      type: Number,
      default: 2,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    controls: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    visiblePages() {
      const pages = [];

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }

      return pages;
    },
    rangeStart() {
      const start = this.currentPage - this.maxRange;

      return (start > 0) ? start : 1;
    },
    rangeEnd() {
      const end = this.currentPage + this.maxRange;

      return (end < this.pageCount) ? end : this.pageCount;
    },
    nextPage() {
      return this.currentPage + 1;
    },
    prevPage() {
      return this.currentPage - 1;
    },
    hasPrev() {
      return this.currentPage > 1;
    },
    hasNext() {
      return this.currentPage < this.pageCount;
    },
  },
  methods: {
    rangeIncludesPage(page) {
      return this.visiblePages.includes(page);
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
  },
};
</script>

<style lang="scss">
  .m-pagination {
    position: relative;
    &__list {
      display: flex;
      align-items: center;
    }
    &__item {
      font-size: 11px;
      line-height: 13px;
      color: $m-dark-gray;
      margin: 0 2px;
      cursor: pointer;
      display: flex;
      &_current {
        color: $color-main;
      }
      &_dots {
        margin: 0 6px;
        cursor: auto;
      }
    }
    &__arrow {
      position: absolute;
      cursor: pointer;
      top: 1px;
      &_left {
        left: -20px;
      }
      &_right {
        right: -20px;
      }
    }
    &__icon {
      width: 8px;
      height: 4px;
      fill: $m-dark-gray;
    }
    &__controls {
      height: 4px;
    }
  }
</style>
