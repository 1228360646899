import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@icons/LMSIcons/mark.svg'


const _withScopeId = n => (_pushScopeId("data-v-2706c79c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "checked", "value", "disabled", "data-test-id"]
const _hoisted_2 = ["for", "data-test-id"]
const _hoisted_3 = ["data-test-id"]
const _hoisted_4 = ["data-test-id", "textContent"]
const _hoisted_5 = ["data-test-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.classes, "m-checkbox"])
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      name: _ctx.name,
      checked: _ctx.checked,
      value: _ctx.value,
      disabled: _ctx.disabled,
      type: "checkbox",
      class: "m-checkbox__input",
      "data-test-id": `mCheckboxInput${_ctx.dataTestPostfix}`,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "m-checkbox__label",
      "data-test-id": `mCheckboxLabel${_ctx.dataTestPostfix}`
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["m-checkbox__box", _ctx.checkboxBoxClasses]),
        "data-test-id": `mCheckboxLabelBox${_ctx.dataTestPostfix}`
      }, [
        _createVNode(_Transition, { name: "fade-anim" }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_SvgIcon, { data: _imports_0 }, null, 512), [
              [_vShow, _ctx.checked]
            ])
          ]),
          _: 1
        })
      ], 10, _hoisted_3),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["m-checkbox__text", _ctx.checkboxTestClasses]),
            "data-test-id": `mCheckboxLabelText${_ctx.dataTestPostfix}`,
            textContent: _toDisplayString(_ctx.label)
          }, null, 10, _hoisted_4))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["m-checkbox__text", _ctx.checkboxTestClasses]),
            "data-test-id": `mCheckboxLabelText${_ctx.dataTestPostfix}`
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 10, _hoisted_5))
    ], 8, _hoisted_2)
  ], 2))
}