export enum stateTypes {
  ACTIVE = 'active',
  DESTROYED = 'destroyed',
}

export enum viewTypes {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}
