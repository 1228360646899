import { Getters } from 'vuex-smart-module';
import type { PracticeState } from './state';
import { Test, TestTask } from '@/types';

export class PracticeGetters extends Getters<PracticeState> {
  getTestListByThemeId(themeId: number): Test | undefined {
    return this.state.loadedTestLists[themeId];
  }
  currentTestById(testId: number) {
    return this.state.testList.find((item: any) => item.id === testId) as TestTask;
  }
  get lastTestId() {
    const { testList } = this.state;
    return testList[testList.length - 1]?.id;
  }
}
