export enum RulesUploadFile {
  FULL_FILES = 'ext:jpg,jpeg,png,doc,docx,xls,xlsx,pdf,txt,HEIC,HEIF|size:7168',
}

export enum RulesUploadSizes {
  SERVICE_TICKETS_MAX_SIZE = 44040192,
  TEST_FILES_MAX_SIZE = 73400320,
  STATISTIC_TASKS_TEACHER_MAX_SIZE = 73400320,
  TEACHER_CHECK_MAX_SIZE = 73400320,
}

export enum RulesUploadLimits {
  SERVICE_TICKETS_LIMIT_FILES = 6,
  TEST_FILES_LIMIT_FILES = 10,
  STATISTIC_TASKS_TEACHER_LIMIT_FILES = 10,
  TEACHER_CHECK_LIMIT_FILES = 10,
}

export enum TestType {
  TYPE_1 = 'type1',
  TYPE_2 = 'type2',
  TYPE_3 = 'type3',
  TYPE_4 = 'type4',
  TYPE_5 = 'type5',
  TYPE_6 = 'type6',
  TYPE_9 = 'type9',
  TYPE_10 = 'type10',
}
