<template>
  <div class="infinite-scroll">
    <div
      ref="top"
      class="trigger-element"
    />
    <slot/>
    <div
      ref="bottom"
      class="trigger-element"
    />
  </div>
</template>

<script>
export default {
  name: 'MInfiniteScroll',
  props: {
    fetchNextFunction: {
      type: Function,
      required: true,
    },
    fetchPreviousFunction: {
      type: Function,
      default: undefined,
    },
    countNext: {
      type: Number,
      default: null,
    },
    countPrevious: {
      type: Number,
      default: null,
    },
    scrollElementClass: {
      type: String,
      default: null,
    },
    triggerNextClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: {
        next: false,
        previous: false,
      },
      observerBottom: null,
      observerTop: null,
    };
  },
  computed: {
    /** Корневой элемент для IntersectionObserver */
    rootObserverElement() {
      return this.scrollElementClass
        ? document.querySelector(`.${this.scrollElementClass}`)
        : null;
    },
    /**
     * Target-элемент для IntersectionObserver для подгрузки нижних элементов.
     * Если передан triggerNextClass, возвращает элемент с этим классом.
     * Если не передан, то возвращает встроенный элемент по-умолчанию.
     * Таким образом можно управлять моментом запуска функции для подгрузки данных
     */
    triggerNextElement() {
      return this.triggerNextClass
        ? document.querySelector(`.${this.triggerNextClass}`)
        : this.$refs.bottom;
    },
  },
  mounted() {
    // Устанавливаем IntersectionObserver для next и previous
    this.setIntersectionObserverBottom();
    if (this.fetchPreviousFunction) {
      this.setIntersectionObserverTop();
    }
  },
  destroyed() {
    // Отключаем Observers
    this.observerBottom.disconnect();
    if (this.observerTop) {
      this.observerTop.disconnect();
    }
  },
  methods: {
    setIntersectionObserverTop() {
      this.observerTop = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && !this.loading.previous && !!this.countPrevious) {
          this.showPrevious();
        }
      }, { root: this.rootObserverElement });

      this.observerTop.observe(this.$refs.top);
    },
    setIntersectionObserverBottom() {
      this.observerBottom = new IntersectionObserver(async ([entry], observer) => {
        if (entry.isIntersecting && !this.loading.next && !!this.countNext) {
          await this.showNext();
          /*
          После подгрузки каждый раз обнуляем и заново запускаем observer.
          Нужно для случаев, если первые элементы загрузились, а в root-элементе ещё есть место, т.е. entry.isIntersecting === true.
          Т.е. другими словами - перепроверяем, есть ли пересечение
          */
          observer.unobserve(entry.target);
          observer.observe(entry.target);
        }
      }, { root: this.rootObserverElement });

      /*
      MInfiniteScroll рендерится ДО того, как загрузился список элементов.
      По countNext проверяем, загрузились ли элементы и только после этого начинаем следить
      */
      const unwatch = this.$watch('countNext', (val) => {
        if (val !== null) {
          this.observerBottom.observe(this.triggerNextElement);
          if (unwatch) {
            unwatch();
          }
        }
      }, { immediate: true });
    },
    async showNext() {
      try {
        this.loading.next = true;
        await this.fetchNextFunction();
      } catch (err) {
        throw Error(err);
      } finally {
        this.loading.next = false;
      }
    },
    async showPrevious() {
      try {
        this.loading.previous = true;
        await this.fetchPreviousFunction();
      } catch (err) {
        throw Error(err);
      } finally {
        this.loading.previous = false;
      }
    },
  },
};
</script>
