import { Actions } from 'vuex-smart-module';
// import { todoListBlockTypes } from './enums';
import Color from 'color';
import { axios } from '@/services/api';
import type { RootGetters } from './getters';
import type { RootMutations } from './mutations';
import type { RootState } from './state';

const accessTypes = {
  xls: 'application/vnd.ms-excel',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip',
  doc: 'application/msword',
  pdf: 'application/pdf',
  mp3: 'audio/mp3',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  csv: 'text/csv',
  txt: 'text/plain',
  mpg: 'video/mpeg',
  mpeg: 'video/mpeg',
  webm: 'video/webm',
  mp4: 'video/mp4',
  m4v: 'video/mp4',
  mov: 'video/quicktime',
  heif: 'image/heif',
  heic: 'image/heic',
};

function themeLoader() {
  const lStore = window.localStorage;
  if (lStore) {
    const cachedColor = lStore.getItem('MAIN_COLOR');
    if (cachedColor) {
      document.documentElement.style.setProperty('--base', cachedColor);
      document.documentElement.style.setProperty('--base-lighten', Color(cachedColor).lighten(0.04).hex());
      document.documentElement.style.setProperty('--base-darken-20', Color(cachedColor).saturate(0.1).darken(0.12).hex());
      document.documentElement.style.setProperty('--base-darken-40', Color(cachedColor).darken(0.4).rotate(4).hex());
    }
  }
}

export const getBaseColor = () => {
  const lStore = window.localStorage;
  if (lStore) {
    return lStore.getItem('MAIN_COLOR');
  }
};

function handleColor(color: string) {
  const lStore = window.localStorage;
  if (lStore) {
    const cachedColor = lStore.getItem('MAIN_COLOR');
    if (cachedColor !== color) {
      lStore.setItem('MAIN_COLOR', color);
      themeLoader();
    }
  }
}

export class RootActions extends Actions<
RootState,
RootGetters,
RootMutations,
RootActions
> {
  async loadTerritoryConfig() {
    const response = await axios.get('/system/territory-config');

    this.commit('setItem', {
      item: 'territoryConfig',
      value: response.data,
    });

    return response;
  }
  async loadSystemConfig() {
    const response = await axios.get('/system/system-config');
    const systemConfig = response.data;
    this.commit('setItem', {
      item: 'systemConfig',
      value: systemConfig,
    });
    if (systemConfig.MAIN_COLOR) {
      handleColor(systemConfig.MAIN_COLOR);
    }

    return response;
  }
  async loadPermissions() {
    const response = await axios.get('/system/permissions');
    this.commit('SET_PERMISSIONS', response.data);
    return response;
  }
  // updateLanguage() {
  //   if (this.state.territoryConfig) {
  //     this.commit('setItem', {
  //       item: 'language',
  //       value: this.state.territoryConfig.language,
  //     });

  //     return this.state.territoryConfig.language;
  //   }
  // }
  async loadUserGroups() {
    const res = await axios.get('/profile/student/groups');

    this.commit('setItem', {
      item: 'userGroups',
      value: res.data,
    });

    return res;
  }
  async loadUserStats(groupId: number) {
    const res = await axios.get(`profile/external/group/${groupId}/statistics`);

    this.commit('setItem', {
      item: 'userStats',
      value: res.data,
    });

    return res;
  }
  async loadTodoData(groupId: number) {
    const res = await axios.get(`/schedule/student/schedule/groups/${groupId}/todolist`);

    this.commit('setItem', {
      item: 'todoListData',
      value: res.data,
    });

    return res;
  }
  async loadMediaContent(payload: {
    path: string;
    file: File;
    type: string;
  }) {
    const { path, file, type } = payload;
    const extension = file.name.split('.').pop();
    // Google Chrome версий 94-95 имеет баг, где не считывает тип файла в JS
    const validType = type === '' ? (extension && (accessTypes as Record<string, string>)[extension]) : type;

    return axios.post(`/storage/${path}`, file, {
      headers: {
        'Content-Type': validType as any,
        'X-File-Extension': extension?.toLowerCase() as any,
      },
    });
  }
}
