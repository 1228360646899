import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81302b26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-toolbar-user" }
const _hoisted_2 = { class: "user-menu" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "content-user-menu font-400-14-16" }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VAvatar = _resolveComponent("VAvatar")!
  const _component_VTransitionExpand = _resolveComponent("VTransitionExpand")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "toggler",
      class: "toggler btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglerUserMenu()))
    }, [
      _createVNode(_component_VAvatar, {
        class: "avatar",
        text: _ctx.fullNameNormalized
      }, null, 8, ["text"]),
      _createElementVNode("div", {
        class: _normalizeClass(["chevron", { unfolded: _ctx.unfolded }])
      }, null, 2)
    ], 512),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createVNode(_component_VTransitionExpand, null, {
        default: _withCtx(() => [
          (_ctx.unfolded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", {
                    class: "full-name font-700-14-16",
                    textContent: _toDisplayString(_ctx.fullNameInMenu)
                  }, null, 8, _hoisted_5),
                  _createElementVNode("div", {
                    class: "btn item-user-menu",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args))),
                    textContent: 'Выход'
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])), [
      [_directive_click_outside, _ctx.onOutsideClick]
    ])
  ]))
}