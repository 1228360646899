<template>
  <div class="m-modal">
    <div
      class="m-modal__wrap"
      @click="onWrapClick"
    />
    <div
      :class="withoutPaddings ? 'm-modal__container_without-paddings' : ''"
      class="m-modal__container"
    >
      <m-button
        v-if="canClose"
        view="icon"
        size="content"
        class="m-modal__close-btn"
        data-test-id="closeModalBtn"
        @click="$emit('close')"
      >
        <SvgIcon name="@icons/LMSIcons/close.svg"/>
      </m-button>
      <div class="m-modal__container-inner">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MModal',
  props: {
    canClose: {
      type: Boolean,
      default: true,
    },
    withoutPaddings: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onWrapClick() {
      if (this.canClose) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.m-modal {
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 20px 0;
  overflow-y: auto;
  text-align: center;

  &:before {
    content:'';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &__wrap {
    background: rgba(255, 255, 255, .9);
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  &__container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);
    min-height: 200px;
    min-width: 250px;
    padding: 40px 20px 12px;
    z-index: 15;

    max-width: 100%;
    overflow-x: auto;

    &_without-paddings {
      padding: 40px 0 12px;
    }
  }

  &__container-inner {
    position: relative;
    width: fit-content;
  }

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
