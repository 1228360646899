<template>
  <transition-group
    :name="name"
    @after-enter="afterEnter"
    @enter="enter"
    @leave="leave"
  >
    <slot/>
  </transition-group>
</template>

<script>
import transition from '../../mixins/transition';

export default {
  name: 'MTransitionGroup',
  mixins: [transition],
};
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
