import MContainer from './components/grid/MContainer';
import MRow from './components/grid/MRow';
import MCol from './components/grid/MCol';
import MContentWrapper from './components/grid/MContentWrapper';

import MTextarea from './components/controls/MTextarea/index';
import MInput from './components/controls/MInput/index';
import MForm from './components/controls/MForm';
import MFieldNew from './components/controls/MFieldNew';
import MTabsSelect from './components/controls/MTabsSelect';
import MCheckbox from './components/controls/MCheckbox';
import MStarRating from './components/controls/MStarRating';
import MToggle from './components/controls/MToggle';
import MDatePicker from './components/controls/MDatePicker';
import MRadio from './components/controls/MRadio';
import MButton from './components/controls/MButton';
import MScrollBtn from './components/controls/MScrollBtn';
import MLink from './components/controls/MLink';
import MSelect from './components/controls/MSelect';
import MPagination from './components/controls/MPagination';
import MLabel from './components/controls/MLabel';
import MFiles from './components/controls/MFiles';

import MAvatar from './components/common/MAvatar';
import MCounter from './components/common/MCounter';
import MExpansion from './components/common/MExpansion';
import MFileList from './components/common/MFileList';
import MFilesList from './components/common/MFilesList';
import MList from './components/common/MList';
import MModal from './components/common/MModal';
import MxModal from './components/common/MxModal';
import MPlank from './components/common/MPlank';
import MPopup from './components/common/MPopup';
import MPreloader from './components/common/MPreloader';
import MTable from './components/common/MTable';
import MTitle from './components/common/MTitle';
import MTree from './components/common/MTree';
import MHint from './components/common/MHint';
import MStatisticScale from './components/common/MStatisticScale';
import MSpoiler from './components/common/MSpoiler';
import MProgressBar from './components/common/MProgressBar';
import MProgressCircle from './components/common/MProgressCircle.vue';
import MInfiniteScroll from './components/common/MInfiniteScroll';

import MAudioPlayer from './components/common/MAudioPlayer';
import MVideoPlayer from './components/common/MVideoPlayer';

import clickOutside from './mixins/clickOutside';

import MFragment from './components/common/MFragment';
import MTransition from './components/common/MTransition';
import MTransitionGroup from './components/common/MTransitionGroup';

import MTextareaNew from '@/uikit/maxify/components/controls/MTextarea/MTextareaNew.vue';

export default {
  install(Vue, options = {}) {
    Vue.component('mContainer', MContainer);
    Vue.component('mRow', MRow);
    Vue.component('mCol', MCol);
    Vue.component('mContentWrapper', MContentWrapper);

    Vue.component('MInput', MInput);
    Vue.component('MTextarea', MTextarea);

    Vue.component('mForm', MForm);
    Vue.component('mFieldNew', MFieldNew);
    Vue.component('mTabsSelect', MTabsSelect);
    Vue.component('mCheckbox', MCheckbox);
    Vue.component('mStarRating', MStarRating);
    Vue.component('mToggle', MToggle);
    Vue.component('mDatePicker', MDatePicker);
    Vue.component('mRadio', MRadio);
    Vue.component('mButton', MButton);
    Vue.component('MScrollBtn', MScrollBtn);
    Vue.component('mLink', MLink);
    Vue.component('mSelect', MSelect);
    Vue.component('mPagination', MPagination);
    Vue.component('mLabel', MLabel);
    Vue.component('mFiles', MFiles);

    Vue.component('mFragment', MFragment);
    Vue.component('mCounter', MCounter);
    Vue.component('mExpansion', MExpansion);
    Vue.component('mPopup', MPopup);
    Vue.component('mFileList', MFileList);
    Vue.component('mList', MList);
    Vue.component('mAvatar', MAvatar);
    Vue.component('mFilesList', MFilesList);
    Vue.component('mModal', MModal);
    Vue.component('mPlank', MPlank);
    Vue.component('mPreloader', MPreloader);
    Vue.component('mTable', MTable);
    Vue.component('mTitle', MTitle);
    Vue.component('mTree', MTree);
    Vue.component('mHint', MHint);
    Vue.component('mStatisticScale', MStatisticScale);
    Vue.component('mSpoiler', MSpoiler);
    Vue.component('mProgressBar', MProgressBar);
    Vue.component('MProgressCircle', MProgressCircle);
    Vue.component('MInfiniteScroll', MInfiniteScroll);
    Vue.component('MxModal', MxModal);

    Vue.component('mAudioPlayer', MAudioPlayer);
    Vue.component('mVideoPlayer', MVideoPlayer);
    Vue.component('MTransition', MTransition);
    Vue.component('MTransitionGroup', MTransitionGroup);

    Vue.component('MTextareaNew', MTextareaNew)
  },
};
