<template>
  <ul class="m-files-list">
    <li
      v-for="(file, idx) in files"
      :key="file[url]"
      class="m-files-list__file"
    >
      <div
        class="file"
        data-test-id="uploadedFile"
      >
        <a
          :href="file[url]"
          class="file__link"
          target="_blank"
        >
          <div>
            <SvgIcon
              class="file__download-icon"
              data="@icons/LMSIcons/download_file.svg"
            />
          </div>
          <span
            class="file__name"
            :data-test-id="`mFileName${idx}`"
          >
            {{ file[name] }}
          </span>
        </a>
        <div
          v-if="!disabled"
          class="file__remove"
        >
          <m-button
            class="file__remove-btn"
            view="icon"
            size="content"
            @click="$emit('removeFile', file[url])"
          >
            <SvgIcon
              class="file__remove-icon"
              data="@icons/LMSIcons/close.svg"
            />
          </m-button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MFilesList',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'name',
    },
    url: {
      type: String,
      default: 'url',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-files-list {
    &__file {
      height: 24px;
      margin: 8px 0;
      position: relative;
    }

    .file {
      align-items: center;
      display: flex;

      &__link {
        display: flex;
        align-items: center;
      }

      &__download-icon {
        fill: $m-dark-gray;
        height: 24px;
        width: 32px;
      }

      &__name {
        color: $m-text;
        font-size: 13px;
        margin: 0 16px 0 8px;
        transition: color 0.2s;
      }

      &__remove {
        &-icon {
          fill: $m-bright-red;
          height: 10px;
          width: 10px;
        }
      }

      &:hover .file__name {
        color: $color-main;
      }
    }
  }
</style>
