import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';
import { mapTestToAnswerList } from './helpers';
import type { ExamState } from './state';

export class ExamMutations extends Mutations<ExamState> {
  /**
   * Отображение списка тестов для точек контроля.
   * @param isExam условие для списка у ученика, без него для преподавателя
   */
  SET_TEST_LIST({ list, lessonId, isExam }: {
    list: any;
    lessonId: number;
    isExam?: boolean;
  }) {
    this.state.testList = list && isExam
      ? list.map((item: any) => ({ ...item, isExam: true }))
      : list.map((item: any) => ({ ...item }));
    this.state.studentAnswerList = mapTestToAnswerList({ list, savedAnswer: this.state.currentTestAnswerLocal[lessonId] });
  }
  SET_EXAM_STATS(data: any) {
    this.state.examStats = data;
  }
  SET_EXAM_INFO({ examId, data }: {
    examId: number;
    data: any;
  }) {
    // Vue.set(this.state.examsInfo, examId, data);
    this.state.examsInfo[examId] = data;
  }
  UPDATE_TEST_ANSWER({ id, value }: {
    id: number;
    value: any;
  }) {
    this.state.studentAnswerList[id].answer = value;
  }
  UPDATE_TEST_ATTACHES({ id, value }: {
    id: number;
    value: any;
  }) {
    this.state.studentAnswerList[id].attaches = value;
  }
  UPDATE_STUDENT_TEST_RESULT({ id, value }: {
    id: number;
    value: any;
  }) {
    const test = this.state.testList.find((item) => item.id === id);
    // Vue.set(test, 'studentTestResult', value);
    test.studentTestResult = value;
  }
  UPDATE_CURRENT_TEST_ANSWER_LOCAL({ id, studentId, lessonId, toDelete }: {
    id: number;
    studentId: number;
    lessonId: number;
    toDelete: boolean;
  }) {
    if (toDelete) {
      this.state.currentTestAnswerLocal[lessonId] = null;
      return;
    }

    this.state.currentTestAnswerLocal[lessonId] = {
      id,
      studentId,
      data: this.state.studentAnswerList[id],
    };
  }
  SET_FINISHED_STATUS(subjectId: number) {
    // Vue.set(this.state.examsInfo[subjectId], 'isFinished', true);
    this.state.examsInfo[subjectId].isFinished = true;
  }
}
