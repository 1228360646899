import { RouteName } from '@/enums';

import type { RouteRecordRaw } from 'vue-router';

const _studentTheme = () => import(
  /* webpackChunkName: "student-theme" */
  '@/views/StudentTheme/StudentTheme.vue'
);
const _studentThemeTheory = () => import(
  /* webpackChunkName: "student-theme-theory" */
  '@/views/StudentTheme/StudentThemeTheory.vue'
);
const _studentThemeVideo = () => import(
  /* webpackChunkName: "student-theme-video" */
  '@/views/StudentTheme/StudentThemeVideo.vue'
);
const _studentThemePractice = () => import(
  /* webpackChunkName: "student-theme-practice" */
  '@/views/StudentTheme/StudentThemePractice.vue'
);
const _studentThemePracticeTask = () => import(
  /* webpackChunkName: "student-theme-practice" */
  '@/components/lessonPage/tests/practice/TestWrapper/CurrentTestBody/index.vue'
);

export const studentThemeRoutes: RouteRecordRaw[] = [
  {
    path: '/themes/:themeId(\\d+)/',
    name: RouteName.STUDENT_THEME,
    redirect: { name: RouteName.STUDENT_THEME_THEORY },
    component: _studentTheme,
    children: [
      {
        path: 'theory',
        name: RouteName.STUDENT_THEME_THEORY,
        component: _studentThemeTheory,
        meta: {
          bugReportContentType: 'theory',
        },
      },
      {
        path: 'video',
        name: RouteName.STUDENT_THEME_VIDEO,
        component: _studentThemeVideo,
        meta: {
          bugReportContentType: 'video',
        },
      },
      {
        path: 'practice',
        name: RouteName.STUDENT_THEME_PRACTICE,
        component: _studentThemePractice,
        redirect({ params }) {
          const { themeId } = params;
          return { name: RouteName.STUDENT_THEME_PRACTICE_TASK, params: { themeId, taskNumber: 1 } };
        },
        meta: {
          bugReportContentType: 'practice',
        },
        children: [
          {
            path: ':taskNumber(\\d+)',
            name: RouteName.STUDENT_THEME_PRACTICE_TASK,
            component: _studentThemePracticeTask,
          },
        ],
      },
    ],
  },
];
