import type { AxiosInstance } from 'axios';
import type { SectionResponseItem } from '@/types';

export default (axios: AxiosInstance) => ({
  async getItem(id: number) {
    try {
      const response = await axios.get<SectionResponseItem>(`/v1/content-platform/get-section-info/${id}`);
      return { response };
    } catch (error) {
      return { error };
    }
  },
});
