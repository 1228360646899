/* validationProvider заменен на это: https://github.com/logaretm/vee-validate/discussions/3544 */
// import { ValidationProvider } from 'vee-validate';
import { defineComponent, h } from 'vue';
import { Field } from 'vee-validate';
import Input from '../Input/index';
import './MxInput.scss';


export default defineComponent({
  extends: Input,
  name: 'MxInput',
  props: {
    vid: {
      type: [String, Number],
      default: undefined,
    },
    // Правила валидации vee-validate
    // https://logaretm.github.io/vee-validate/guide/rules.html#rules
    rules: {
      type: [Object, String],
      default: '',
    },
    // Через какое время сообщение об ошибке пропадет
    errorTimeout: {
      type: Number,
      default: 3000,
    },
    customError: {
      type: [String, Object],
      default: null,
    },
    errorStyle: [Object, String],
    state: {
      type: String,
      default: null,
      validator(value) {
        return ['success', 'error'].includes(value);
      },
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
      defaultError: null,
      failedRules: null,
      inputState: null,
    };
  },
  computed: {
    isErrorVisible() {
      return this.defaultError && !this.isActive;
    },
    computedClasses() {
      return this.state ? `mx-input_state_${this.state}` : '';
    },
    errorMsg() {
      if (this.customError) {
        if (typeof this.customError === 'string') {
          return this.customError;
        }
        if (typeof this.customError === 'object') {
          const mergedErrors = { ...this.failedRules, ...this.customError };
          return Object.values(mergedErrors)[0];
        }
      }
      return this.defaultError;
    },
  },
  watch: {
    inputState(props) {
      this.defaultError = props.dirty && props.errors && props.errors[0];
      this.failedRules = props.failedRules;
    },
  },
  methods: {
    /** @public */
    focus() {
      if (!this.$refs.input) return;
      if (document.activeElement !== this.$refs.input) {
        return this.$refs.input.focus();
      }
    },
    handleInput(e) {
      this.$refs.validationProvider.validate(e);
      this.$emit('input', e.target.value);
      this.isActive = true;
    },
    handleBlur(e) {
      this.$refs.validationProvider.validate(e);
      this.isActive = false;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isActive = true;
      }, this.errorTimeout);
      this.$emit('blur', e);
    },
    genCrossIcon() {
      if (!this.rules) {
        return null;
      }

      return h('svgicon', {
        attrs: { name: 'close' },
        class: 'mx-input__cross',
        style: !this.defaultError && { display: 'none' },
      });
    },
    genErrorMessage() {
      if (!this.rules) {
        return null;
      }

      return (this.isErrorVisible) && h('transition', {
        attrs: { name: 'fade-anim' },
      }, [
        h('span', {
          attrs: { class: 'mx-input__error' },
          style: this.errorStyle,
        }, this.errorMsg),
      ]);
    },
    renderInput() {
      // TODO заменил
      // const input = Input.options.methods.renderInput.call(this);

      // const input = Input.methods.renderInput.call(this);
      //
      // // const input = resolveComponent(Input);
      // input.data.ref = 'input';
      //
      // input.data.on = {
      //   ...this.$listeners,
      //   input: this.handleInput,
      //   blur: this.handleBlur,
      // };
      //
      // input.data = {
      //   ...this.$attrs,
      //   input: this.handleInput,
      //   blur: this.handleBlur,
      // };
      //
      // input.data.attrs['data-test-id'] = this.dataTestId;
      //
      // return input;
      return h(Input, {
        ...this.$attrs,
        onInput: this.handleInput,
        onBlur: this.handleBlur,
        ref: 'input'
      });
    },
    renderController() {
      return [
        this.renderInput(),
        this.genCrossIcon(),
        this.genErrorMessage(),
      ];
    },
  },
  render() {
    const self = this;
    const vid = self.vid
      ? String(self.vid)
      : this.innerId;

    return self.renderContent();
    // return h(
    //   Field,
    //   {
    //     ref: 'validationProvider',
    //     slim: true,
    //     // as: 'div',
    //     // vid, // забил на vid, тк он нигде не используется, он просто прокинут сюда был, на случай "если".
    //     rules: self.rules,
    //     name: self.name || self.label,
    //     // props: { slim: true }, // зачем тут было это свойство - не понятно, так как был указан тег div.
    //     // вообще говоря это свойство говорит, чтобы никакой элемент не рендерился
    //     // https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#nested-slots
    //     class: [ 'mx-input', this.computedClasses ],
    //   },
    //   {
    //     default(props) {
    //       self.inputState = props;
    //       return self.renderContent();
    //     },
    //   }
    // );

    // return createElement(ValidationProvider, {
    //   ref: 'validationProvider',
    //   attrs: {
    //     tag: 'div',
    //     vid,
    //     rules: self.rules,
    //     name: self.name || self.label,
    //   },
    //   props: { slim: true },
    //   staticClass: ['mx-input'],
    //   class: this.computedClasses,
    //   scopedSlots: {
    //     default: (props) => {
    //       // props - состояние validationProvider
    //       // https://logaretm.github.io/vee-validate/api/validation-provider.html#scoped-slot-props
    //       this.inputState = props;
    //       return this.renderContent();
    //     },
    //   },
    // });
  },
});
