import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a0dad37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-toggle" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "value", "disabled", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass([{ 'm-toggle__slot_checked': _ctx.checked }, "m-toggle__slot"])
    }, [
      _createElementVNode("input", {
        id: _ctx.id,
        name: _ctx.name,
        value: _ctx.value,
        disabled: _ctx.disabled,
        checked: _ctx.checked,
        type: "checkbox",
        class: "m-toggle__input",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, null, 40, _hoisted_3),
      _createElementVNode("div", {
        class: _normalizeClass([{ 'm-toggle__core_checked': _ctx.checked }, "m-toggle__core"])
      }, null, 2)
    ], 10, _hoisted_2)
  ]))
}