import { Module } from 'vuex-smart-module';
import { LessonsGetters } from './getters';
import { LessonsActions } from './actions';
import { LessonsMutations } from './mutations';
import { LessonsState } from './state';

import practice from './tests/practice';
import exam from './tests/exam';
import corrections from './tests/corrections';
import debts from './tests/debts';

export default new Module({
  state: LessonsState,
  mutations: LessonsMutations,
  actions: LessonsActions,
  getters: LessonsGetters,
  modules: {
    practice,
    exam,
    corrections,
    debts,
  },
});
