<template>
  <ul class="m-file-list">
    <li
      v-for="(file, index) in files"
      :key="`file-${index}`"
      class="m-file-list__item"
    >
      <div class="m-file-list__file">
        <SvgIcon
          data="@icons/LMSIcons/clip.svg"
          class="m-file-list__file-icon"
        />
        <a
          :href="file.filePath"
          target="_blank"
          class="m-file-list__file-link"
          :data-test-id="`mFileItemLink${index}`"
        >
          {{ file.fileName || `файл ${ index + 1 }` }}
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MFileList',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.m-file-list {
  &__item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__file {
    align-items: center;
    display: flex;
  }

  &__file-icon {
    margin-right: 8px;
  }

  &__file-link {
    color: inherit;
    text-decoration: none;
  }
}
</style>
