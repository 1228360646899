import { requestsController } from '@/utils/requestsController';
import type { AxiosRequestConfig } from 'axios';

export const addRequestToController = (request: AxiosRequestConfig) => {
  const { requestTitle } = request;
  if (!requestTitle) return request;
  requestsController.add(requestTitle, new AbortController());
  return request;
};

export const removeRequestFromController = (payload: any) => {
  const { requestTitle } = payload.config;
  if (requestTitle && requestsController.contains(requestTitle)) requestsController.remove(requestTitle);
  return payload;
};
