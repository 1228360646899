import type { AxiosInstance } from 'axios';
import type { Test } from '@/types';

export default (axios: AxiosInstance) => ({
  getThemeTest(themeId: number) {
    return axios.get<Test>(`/v1/student/practice/tests/theme-id/${themeId}`);
  },
  sendTaskAnswer(themeId: number, testVersionId: number, payload: any) {
    const url = `/v1/student/practice/save-test-result/theme-id/${themeId}/test-version-id/${testVersionId}`;
    return axios.post(url, payload);
  },
  updateSolutionStatus(themeId: number, testVersionId: number, solutionStatus: boolean) {
    const url = `/v1/student/practice/save-test-result/theme-id/${themeId}/test-version-id/${testVersionId}`;
    return axios.post(url, { showSolution: solutionStatus });
  },
});
