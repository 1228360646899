import Color from 'color';

export default function () {
  const lStore = window.localStorage;
  if (lStore) {
    const cachedColor = lStore.getItem('MAIN_COLOR');
    if (cachedColor) {
      document.documentElement.style.setProperty('--base', cachedColor);
      document.documentElement.style.setProperty('--base-lighten', Color(cachedColor).lighten(0.04).hex());
      document.documentElement.style.setProperty('--base-darken-20', Color(cachedColor).saturate(0.1).darken(0.12).hex());
      document.documentElement.style.setProperty('--base-darken-40', Color(cachedColor).darken(0.4).rotate(4).hex());
    }
  }
}

export const getBaseColor = () => {
  const lStore = window.localStorage;
  if (lStore) {
    return lStore.getItem('MAIN_COLOR');
  }
};
