import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';
import cloneDeep from 'lodash/cloneDeep';
import { getAnswerListItem, mapTestToAnswerList } from './helpers';
import type { PracticeState } from './state';
import type { Test, TestTask } from '@/types';

type SaveTestListPayload = {
  themeId: number;
  list: Test;
}

export class PracticeMutations extends Mutations<PracticeState> {
  // SET_CURRICULUM_SUBJECT(curriculumSubjects: any) {
  //   this.state.curriculumSubjects = curriculumSubjects;
  // }
  // SET_TREE(tree: any) {
  //   this.state.subjectTree = tree;
  // }
  // SET_SUBJECT_STATS(data: any) {
  //   this.state.subjectStats = data;
  // }
  SAVE_TEST_LIST({ themeId, list }: SaveTestListPayload ) {
    this.state.loadedTestLists[themeId] = list;
  }
  SET_TEST_LIST(list: any) {
    this.state.testList.splice(0, this.state.testList.length, ...list);
    // TODO не выполняется нормально, переписать функцию?
    this.state.studentAnswerList = mapTestToAnswerList(list);
  }
  UPDATE_TEST_ANSWER({ id, value }: { id: number; value: any }) {
    // TODO совпадает ли структура с тем, что приходит сейчас?
    this.state.studentAnswerList[id].answer = value;
  }
  UPDATE_TEST_ATTACHES({ id, value }: { id: number; value: any }) {
    this.state.studentAnswerList[id].attaches = value;
  }
  UPDATE_TEST_DISABLE({ id, disabled }: { id: number; disabled: boolean }) {
    this.state.studentAnswerList[id].disabled = disabled;
  }
  UPDATE_STUDENT_TEST_RESULT({ id, value }: { id: number; value: any }) {
    const updatedTestIndex = this.state.testList.findIndex((t: any) => t.id === id);
    const updatedTest = cloneDeep(this.state.testList[updatedTestIndex]);

    // Замена объекта теста на обновленный
    updatedTest.testResult = value;
    // Vue.set(this.state.testList, updatedTestIndex, updatedTest);
    this.state.testList[updatedTestIndex] = updatedTest;
  }
  SPEND_ATTEMPT({ id }: { id: number }) {
    const updatedTestIndex = this.state.testList.findIndex((t: any) => t.id === id);
    const updatedTest = cloneDeep(this.state.testList[updatedTestIndex]);

    // Уменьшаем количество попыток на 1
    updatedTest.attemptsCountLeft -= 1;
    // Vue.set(this.state.testList, updatedTestIndex, updatedTest);
    this.state.testList[updatedTestIndex] = updatedTest;
  }
  UPDATE_STUDENT_LIST_ANSWER({ id }: { id: number }) {
    const { testResult } = this.state.testList.find((t: any) => t.id === id) as TestTask;
    this.state.studentAnswerList[id] = getAnswerListItem(testResult);
  }
  DELETE_STUDENT_TEST_RESULT({ id }: { id: number }) {
    const testToModify = this.state.testList.find((t: any) => t.id === id) as TestTask;
    // Удаляем только на фронте поле studentTestResult для возможности снова послать тест
    // Для правильно отвеченных тестов бэк вернет ошибку(400)
    testToModify.testResult = null;
  }
}
