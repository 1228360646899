export default (callback, ms) => {
  let timeout = null;

  return (...args) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        callback.apply(this, args);
        timeout = null;
      }, ms);
    }
  };
};
