
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VAvatar',
  props: {
    text: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      default: '',
    },
  },
  computed: {
    initials() {
      const firstLettersOfWords = this.text.split(' ').map((word) => word[0]);
      return firstLettersOfWords.join('').substring(0, 2);
    },
    style() {
      return { backgroundImage: `url('${this.avatar}')` };
    },
  },
});
