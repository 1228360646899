import { Getters } from 'vuex-smart-module';
import type { ExamState } from './state';

export class ExamGetters extends Getters<ExamState> {
  currentTestById(testId: number) {
    return this.state.testList.find((item) => item.id === testId);
  }
  get lastTestId() {
    const { testList } = this.state;
    return testList[testList.length - 1]?.id;
  }
  get firstTestId() {
    const { testList } = this.state;
    return testList[0]?.id;
  }
  getExamInfoBySubjectId(examSubjectId: number) {
    return this.state.examsInfo[examSubjectId];
  }
}
