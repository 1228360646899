<template>
  <form
    class="m-form"
    @submit.prevent="onSubmit"
  >
    <slot/>
  </form>
</template>

<script>
export default {
  name: 'MForm',
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-form {
    &__row {
      margin-bottom: 10px;
    }
  }
</style>
