import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a37133d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-notify" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotifyItem = _resolveComponent("NotifyItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "notify",
      tag: "ul",
      class: "m-notify__list"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeItems, (item) => {
          return (_openBlock(), _createBlock(_component_NotifyItem, {
            key: `m-notify-item-${item.id}`,
            item: item,
            class: "m-notify__item",
            onDestroy: ($event: any) => (_ctx.destroy(item))
          }, null, 8, ["item", "onDestroy"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}