import { reactive } from 'vue';
import throttle from '@/plugins/utils/throttle';
/*
Plugin требует opts.breakpoints объект с ключами брейкпойнтов и значениями в числах
opts.throttle - задержка вызовов между вычислениями ресайза
Пример:
  opts = {
    breakpoints: {
        tiny: 640,
        small: 764,
        medium: 1024,
        wide: 1200,
    },
    throttle: 66,
  };
  Тогда $adaptive.is.tiny будет true при window.innerWidth < 640
*/
export default {
  install(app, opts) {
    const { breakpoints } = opts;
    const breakpointsController = Object.keys(breakpoints)
      .reduce((obj, item) => {
        obj[item] = false;
        // Vue.set(obj, item, false); // было
        return obj;
      }, {});

    const root = {
      is: breakpointsController,
      current: Object.keys(breakpoints),
      resize: throttle(() => {
        /* eslint-disable */
        const width = document.documentElement.clientWidth;
        for (let point in breakpoints) {
          if (width < breakpoints[point]) {
            root.is[root.current] = false;
            root.is[point] = true;
            root.current = point;
          } else {
            root.is[point] = false;
          }
        }
        /* eslint-enable */
      }, opts.throttle || 17),
    };

    window.addEventListener('resize', root.resize);
    if ('orientationchange' in window) {
      root.orientationchange = () => {
        window.dispatchEvent(new Event('resize'));
      };
      window.addEventListener('orientationchange', root.orientationchange);
    }
    window.dispatchEvent(new Event('resize'));

    app.config.globalProperties.$adaptive = reactive(root);
  },
};
