import { RouteName, LayoutName } from '@/enums';

import type { RouteRecordRaw } from 'vue-router';

const _oauthResult = () => import(
  /* webpackChunkName: "oauth-result" */
  '@/views/oauth/OAuthResult.vue'
);

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/auth/oauth-result',
    name: RouteName.OAUTH_RESULT,
    component: _oauthResult,
    meta: {
      layout: LayoutName.EMPTY,
    },
  },
];
