
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MToggle',
  props: {
    value: {
      type: Boolean,
    },
    id: {
      type: String,
      default: 'option'
    },
    name: {
      type: String,
      default: 'option'
    },
    disabled: {
      type: Boolean
    },
    independentOfDisable: {
      type: Boolean
    }
  },
  computed: {
    checked() {
      return this.value && (this.independentOfDisable || !this.disabled);
    }
  },
  methods: {
    onChange(event: any) {
      this.$emit('change', event.target.checked);
    }
  }
})
