import { Module } from 'vuex-smart-module';
import { ExamGetters } from './getters';
import { ExamActions } from './actions';
import { ExamMutations } from './mutations';
import { ExamState } from './state';

export default new Module({
  state: ExamState,
  mutations: ExamMutations,
  actions: ExamActions,
  getters: ExamGetters,
});
