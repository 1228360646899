<template>
  <div
    v-if="options"
    class="m-tabs-select"
    data-test-id="_contentFilterTabs"
  >
    <template v-for="(option, index) in options">
      <input
        v-if="!option.hidden"
        :id="`${name}-${index}`"
        :key="`input-${name}-${index}`"
        v-model="localValue"
        :value="option.value"
        :name="name"
        :class="{ 'm-tabs-select__input_locked': option.locked }"
        type="radio"
        class="m-tabs-select__input"
        data-test-id="filterTabInput"
      >
      <label
        v-if="!option.hidden"
        :key="`label-${name}-${index}`"
        :for="`${name}-${index}`"
        :class="{ 'm-tabs-select__label_active': option.isActive }"
        class="m-tabs-select__label"
        :style="labelStyles"
        data-test-id="filterTabLabel"
        @click="emitValue(option)"
        v-text="option.text"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'MTabsSelect',
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    name: {
      type: String,
      default: 'option',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    labelStyles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localValue: '',
    };
  },
  watch: {
    value: {
      handler(val) {
        this.localValue = val;
      },
      immediate: true,
    },
    localValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    emitValue({ value, locked }) {
      if (!locked) {
        this.$emit('click', value);
      }
    },
  },
};
</script>

<style lang="scss">
.m-tabs-select {
  display: inline-flex;
  height: 32px;
  border-radius: 32px;
  color: $color-main;
  font-size: 13px;
  user-select: none;

  &__input {
    display: none;
    &:first-of-type {
      + label {
        border-left: 1px solid $color-main;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }
    }
    &:last-of-type {
      + label {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }
    &:checked {
      + label {
        background-color: $color-main;
        color: #fff;
      }
    }
    &_locked {
      + label {
        background-color: $m-gray;
        color: #fff;
        border-color: $m-gray;
        box-shadow: -1px 0 0 0 $m-gray;
        &:first-of-type {
          border-left: 1px solid $m-gray;
        }
        &:hover {
          background-color: $m-gray;
        }
      }
      &:checked {
        + label {
          background-color: $m-gray;
        }
      }
    }
  }
  &__label {
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;
    border-top: 1px solid $color-main;
    border-bottom: 1px solid $color-main;
    border-right: 1px solid $color-main;
    transition: all .3s ease;
    white-space: nowrap;
    &_active {
      background-color: $color-main;
      color: #fff;
    }
    &_separated {
      margin-left: 12px;
      border: 1px solid $color-main;
      border-radius: 32px;
    }
  }
}
</style>
