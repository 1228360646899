import { Getters } from 'vuex-smart-module';

import { todoListBlockTypes } from './enums';
// import { LanguageCode } from '@/plugins/localization/enums';
import type { RootState } from './state';

export class RootGetters extends Getters<RootState> {
  get currentGroup() {
    if (this.state.userGroups && this.state.userGroups.length) {
      return this.state.userGroups.find((group: any) => group.id === this.state.currentStudentGroupId) || null;
    }
    return null;
  }
  get groupIsLocked() {
    return this.getters.currentGroup?.locked !== 1;
  }
  getGroupById(id: number) {
    if (!this.state.userGroups?.length) {
      return null;
    }
    return this.state.userGroups?.find((group: any) => group.id === id);
  }
  /**
   * TODO: С хера ли этот кусок кода является бизнес логикой?
   */
  get todoListBlocks() {
    if (this.state.todoListData) {
      return this.state.todoListData.map((item) => {
        switch (item.type) {
          case todoListBlockTypes.REPEAT:
            item.style = 'orange';
            item.icon = 'mark_square';
            break;
          case todoListBlockTypes.PREPARE:
            item.style = 'blue';
            item.icon = 'pages';
            break;
          case todoListBlockTypes.CORRECTION:
            if (item.tests_count === item.tests_right_solved_count) {
              item.style = 'invisible';
            } else {
              item.style = 'red';
              item.icon = 'autorenew';
            }
            break;
          case todoListBlockTypes.SURVEY:
            item.style = 'sea-blue';
            item.icon = 'heart';
            break;
          case todoListBlockTypes.DEBTS:
            item.style = 'gray';
            item.icon = 'debts';
            break;
          default:
            item.style = 'green';
            item.icon = 'mark_square_fill';
            break;
        }

        if (item.stats && item.stats.length) {
          item.stats.forEach((stat: any) => {
            stat.count = stat.text === 'practice' ? stat.try : stat.completed;
          });
        }

        return item;
      });
    }

    return null;
  }
  get canTeacherAddOwnContent() {
    return this.state.systemConfig?.CAN_TEACHER_ADD_OWN_CONTENT === 'true';
  }
  // get isRuZone() {
  //   if (!this.state.systemConfig?.APP_DOMAIN_ZONE) return false;

  //   return this.state.language === LanguageCode.RU
  //     && this.state.systemConfig.APP_DOMAIN_ZONE === LanguageCode.RU;
  // }
  // get isVkKill() {
  //   return [LanguageCode.UK, null, undefined].includes(this.state.language);
  // }
  // get isVkAppEnabled() {
  //   return (this.state.systemConfig ? !!+this.state.systemConfig.VK_APP_ENABLED : false) && !this.getters.isVkKill;
  // }
  get isCrmSmsEnabled() {
    return this.state.systemConfig?.CRM_SMS_ENABLED === 'true';
  }
  get isMindboxEnabled() {
    return this.state.systemConfig?.MINDBOX_ENABLED === 'true';
  }
  get isHintedEnabled() {
    return this.state.territoryConfig?.enableExperrto;
  }
  get vkEnabledForStudentCurrentGroup() {
    return !!this.getters.currentGroup?.vkEnabled;
  }
  // get currentLanguage() {
  //   return this.state.language;
  // }
  get teachersHideMessage() {
    return this.state.systemConfig?.TEACHERS_HIDE_MESSAGES ? JSON.parse(this.state.systemConfig.TEACHERS_HIDE_MESSAGES) : [];
  }
}
