<template>
  <div class="m-date-picker">
    <datepicker
      :value="value"
      :language="pickerLanguage"
      :typeable="typeable"
      :placeholder="placeholder"
      :highlighted="highlighted"
      :format="format"
      input-class="m-date-picker__input"
      class="m-date-picker__picker"
      data-test-id="datePicker"
      @selected="onInput"
    />
    <SvgIcon
      data="@icons/LMSIcons/calendar.svg"
      class="m-date-picker__icon"
    />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ru, uk } from 'vuejs-datepicker/dist/locale';

const locales = {
  ru,
  uk,
};

export default {
  name: 'MDatePicker',
  components: {
    Datepicker,
  },
  props: {
    value: {
      type: [Date, null],
      default: null,
    },
    typeable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Формат даты в placeholder.
    */
    format: {
      type: String,
      default: 'dd.MM.yyyy',
    },
  },
  data() {
    return {
      highlighted: {
        dates: [new Date()],
      },
    };
  },
  computed: {
    currentLanguage() {
      return 'ru';
    },
    pickerLanguage() {
      return locales[this.currentLanguage];
    },
  },
  methods: {
    onInput(date) {
      this.$emit('input', date);
    },
  },
};
</script>

<style lang="scss">
.m-date-picker {
  position: relative;

  &__input {
    border: 1px solid $m-gray;
    width: 100%;
    border-radius: 6px;
    height: 32px;
    padding: 0 12px;
    transition: all .3s ease;

    &:hover {
      border-color: $color-main;
    }

    &:focus {
      border-color: $color-main;
      outline: none;
    }
  }

  &__icon {
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .vdp-datepicker {
    &__calendar {
      width: 245px;

      .cell {
        height: 35px;
        line-height: 35px;
        transition: all .3s ease;

        &.highlighted {
          background: none;
          font-weight: bold;
        }

        &.selected {
          background: $color-main !important;
        }
      }

      .cell:not(.blank):not(.disabled).day:hover,
      .cell:not(.blank):not(.disabled).month:hover,
      .cell:not(.blank):not(.disabled).year:hover {
        border: 1px solid $color-main;
      }
    }
  }
}
</style>
