import type { SystemConfig, TerritoryConfig } from './types';

export class RootState {
  currentStudentGroupId: number | null = null;
  systemConfig: SystemConfig | null = null;
  territoryConfig: TerritoryConfig | null = null;
  permissions: any = null;
  todoListData: null | any[] = null;
  userGroups: any = null;
  userStats: any = null;
  isAdaptive = false;
  loading = false;
}
