
import _capitalize from 'lodash/capitalize';
import { defineComponent } from 'vue';
import VAvatar from '@/components/VAvatar.vue';
import VTransitionExpand from '@/components/VTransition/VTransitionExpand.vue';
import { useCommonStore } from '@/store/common';
import { location } from '@/utils/router';
import { RouteName } from '@/enums';

export default defineComponent({
  name: 'TheToolbarUser',
  components: {
    VAvatar,
    VTransitionExpand,
  },
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      unfolded: false,
    };
  },
  computed: {
    /**
     * TODO: Вынести firstNameCapitalized, lastNameCapitalized, fullNameNormalized
     * в стор, где хранится инфа пришедшая с результата запроса ../me
     *
     * firstNameCapitalized, lastNameCapitalized может быть изьыточным,
     * если и так делается на беке, но я в этом не уверен
     */
    firstNameCapitalized() {
      return this.firstName.split('-').map((el) => _capitalize(el)).join('-');
    },
    lastNameCapitalized() {
      return this.lastName.split('-').map((el) => _capitalize(el)).join('-');
    },
    fullNameNormalized() {
      return [this.firstNameCapitalized, this.lastNameCapitalized].join(' ').trim()
        || 'Аноним Aнонимов';
    },
    fullNameInMenu() {
      return [this.firstNameCapitalized, this.lastNameCapitalized].join(' ').trim();
    },
  },
  methods: {
    togglerUserMenu(status?: boolean) {
      this.unfolded = status ?? !this.unfolded;
    },
    async handleLogout() {
      const commonStore = useCommonStore();

      try {
        await commonStore.logout();
      } catch {
        this.$router.push(location({ name: RouteName.ERROR_500 }));
      } finally {
        this.togglerUserMenu(false);
      }
    },
    onOutsideClick(event: PointerEvent) {
      const composedPath = event.composedPath();
      const togglerEl = this.$refs.toggler as HTMLDivElement;
      if (!composedPath.includes(togglerEl)) this.togglerUserMenu(false);
    },
  },
});
