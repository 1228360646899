
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Mcol',
  inheritAttrs: false,
  props: {
    componentIs: {
      type: String,
      default: 'div'
    },
    col: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    xs: {
      type: [String, Number],
      default: undefined
    },
    sm: {
      type: [String, Number],
      default: undefined
    },
    md: {
      type: [String, Number],
      default: undefined
    },
    lg: {
      type: [String, Number],
      default: undefined
    },
    xl: {
      type: [String, Number],
      default: undefined
    },
    xxl: {
      type: [String, Number],
      default: undefined
    },
  },
  data() {
    return {
      columnsSize: 12,
    }
  },
  computed: {
    cols() {
      return {
        'm-col': this.col,
        'm-col-auto': this.auto,
        'm-col-xs': this.xs && this.validateCol(this.xs),
        'm-col-sm': this.sm && this.validateCol(this.sm),
        'm-col-md': this.md && this.validateCol(this.md),
        'm-col-lg': this.lg && this.validateCol(this.lg),
        'm-col-xl': this.xl && this.validateCol(this.xl),
        [`m-col-xs-${this.xs}`]: this.xs && this.validateSize(this.xs),
        [`m-col-sm-${this.sm}`]: this.sm && this.validateSize(this.sm),
        [`m-col-md-${this.md}`]: this.md && this.validateSize(this.md),
        [`m-col-lg-${this.lg}`]: this.lg && this.validateSize(this.lg),
        [`m-col-xl-${this.xl}`]: this.xl && this.validateSize(this.xl),
      };
    },
    classes() {
      return {
        ...this.cols,
      };
    }
  },
  methods: {
    validateCol(value: string | number): boolean {
      return value === 'col';
    },
    validateSize(value: string | number): boolean {
      return (value > 0 && value <= this.columnsSize) || value === 'auto';
    }
  }
})
