import type { BugReportRequestData } from '@/types';
import type { AxiosInstance } from 'axios';

export default (axios: AxiosInstance) => ({
  async send(payload: BugReportRequestData) {
    try {
      const response = await axios.post('/v1/student/content-complain', {
        grade_subject_title: payload.gradeSubjectTitle,
        message: payload.message,
        theme_id: payload.themeId,
        type: payload.type,
      });
      return { response };
    } catch (error) {
      return { error };
    }
  },
});
