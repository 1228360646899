export default {
  props: {
    name: {
      type: String,
      default: 'slide-down',
    },
  },
  methods: {
    afterEnter(element) {
      element.style.height = 'auto';
    },
    enter(element) {
      const { width } = getComputedStyle(element);

      // Скрываем элемент и позиционируем абсолютно, чтобы он не влиял на другие элементы
      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      // Получаем его высоту
      const { height } = getComputedStyle(element);

      // Все обнуляем
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Принудительно перекрашиваем для корректной работы
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    leave(element) {
      // Тоже самое для плавного удаления
      const { height } = getComputedStyle(element);
      element.style.height = height;

      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
