
import { defineAsyncComponent, defineComponent } from 'vue';
import { globalEvents } from '@/services/globalEvents';
import VButton from '@/components/VButton.vue';
import TheToolbarUser from '@/components/TheToolbar/TheToolbarUser.vue';
import { LayoutName } from '@/enums';

export default defineComponent({
  name: 'App',
  components: {
    TheToolbarUser,
    VButton,
    DefaultLayout: defineAsyncComponent(
      () => import('@/layouts/DefaultLayout.vue'),
    ),
    ErrorLayout: defineAsyncComponent(
      () => import('@/layouts/ErrorLayout.vue'),
    ),
    EmptyLayout: defineAsyncComponent(
      () => import('@/layouts/EmptyLayout.vue'),
    ),
  },
  computed: {
    layout() {
      return this.$route.meta.layout || LayoutName.DEFAULT;
    },
  },
  created() {
    globalEvents.subscribe('logout', () => {
      window.location.reload();
    });
  },
});
