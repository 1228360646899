import axios from 'axios';

import v1 from './v1';

import {
  addRequestToController,
  removeRequestFromController,
} from '@/services/api/interceptors/requestsController';

import { handleError } from '@/services/api/interceptors/errorHandlers';
import { formatQueryParamsToBrackets } from '@/services/api/interceptors/formatters';

export const _axios = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

_axios.interceptors.request.use(addRequestToController);

_axios.interceptors.request.use(formatQueryParamsToBrackets);

_axios.interceptors.response.use(undefined, handleError);

_axios.interceptors.response.use(removeRequestFromController, removeRequestFromController);

export const api = {
  v1: v1(_axios),
};

export { _axios as axios };

export default api;
