import cloneDeep from 'lodash/cloneDeep';

export const getAnswerListItem = (studentTestResult: any) => {
  // answer: массив ответов в ручную: радио, чекбоксы, тексты и тд
  // attaches: массив прикрепленных файлов
  // disabled: статус кнопки отправить см: getTestDisableStatus
  const answerItem: any = { answer: [], attaches: [], disabled: true };

  if (studentTestResult) {
    if (studentTestResult?.answer?.length) {
      answerItem.answer = cloneDeep(studentTestResult.answer);
      answerItem.oldAnswer = cloneDeep(studentTestResult.answer);
    }

    if (studentTestResult?.attaches?.length) {
      answerItem.attaches = cloneDeep(studentTestResult.attaches);
      answerItem.oldAttaches = cloneDeep(studentTestResult.attaches);
    }
  }

  return answerItem;
};

export const mapTestToAnswerList = ({ list, savedAnswer }: any) => list.reduce((resObj: any, { id, studentTestResult }: any) => {
  if (savedAnswer
   && studentTestResult
   && savedAnswer.id === id
   && savedAnswer.studentId === studentTestResult.studentId
  ) {
    resObj[id] = savedAnswer.data;
  } else {
    resObj[id] = getAnswerListItem(studentTestResult);
  }
  return resObj;
}, {});
