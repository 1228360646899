import { Module } from 'vuex-smart-module';
import { PracticeGetters } from './getters';
import { PracticeActions } from './actions';
import { PracticeMutations } from './mutations';
import { PracticeState } from './state';

export default new Module({
  state: PracticeState,
  mutations: PracticeMutations,
  actions: PracticeActions,
  getters: PracticeGetters,
});
