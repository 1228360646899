import { Getters } from 'vuex-smart-module';
import type { CorrectionsState } from './state';

export class CorrectionsGetters extends Getters<CorrectionsState> {
  currentTestById(testId: number) {
    return this.state.testList.find((item: any) => item.id === testId);
  }
  get lastTestId() {
    const { testList } = this.state;
    return testList[testList.length - 1]?.id;
  }
}
