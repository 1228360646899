import { Actions } from 'vuex-smart-module';
import LSTests from '../../../localstorage/student/tests';
import { getResultFromRightAnswers } from './helpers';
import {
  selfCheck,
  isTestAnsweredTrue,
  isTestTeacherCheck,
  checkboxOrRadio,
} from '@/components/lessonPage/tests/common/LessonTestTypes/testStates.js';
import { api, axios } from '@/services/api';
import type { PracticeGetters } from './getters';
import type { PracticeMutations } from './mutations';
import type { PracticeState } from './state';

export class PracticeActions extends Actions<
  PracticeState,
  PracticeGetters,
  PracticeMutations,
  PracticeActions
> {
  async loadSubjectTree(subjectId: number) {
    const { curriculumSubjects, tree } = (await axios.get(`/content/practice/curriculumsubject/${subjectId}`)).data;

    this.commit('SET_CURRICULUM_SUBJECT', curriculumSubjects);
    this.commit('SET_TREE', tree);
  }
  // TODO закомменить все параметры, добавить themeId
  // async loadTestList({ subjectId, type, knowledgeId }: {
  //   subjectId: number;
  //   type: string;
  //   knowledgeId: number;
  // }) {
  async loadTestList(themeId: number) {
    const { data } = await api.v1.tests.getThemeTest(themeId);
    const testsToShow = [
      'type1',
      'type2',
      'type3',
      'type4',
      'type5',
      'type9',
    ];
    const filteredData = data
      .map((task) => {
        task.type = task.type === 'type6' ? 'type9' : task.type;
        return task;
      })
      .filter(task => testsToShow.includes(task.type));
    this.commit('SAVE_TEST_LIST', { themeId, list: filteredData });
    this.commit('SET_TEST_LIST', filteredData);

    filteredData.forEach((test: any) => {
      if (test.type !== 'type6') return;

      // Если есть ответы пришедшие с сервера, то ничего с LS не подставляем

      if (test.testResult) return;

      const answerArray = LSTests.get(test.id);

      if (!answerArray) return;

      this.mutations.UPDATE_TEST_ANSWER({
        id: test.id,
        value: answerArray,
      });
    });
  }
  makeTestActiveByThemeId(themeId: number) {
    this.commit('SET_TEST_LIST', this.state.loadedTestLists[themeId])
  }
  async loadExamList({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    const response = await axios.get(`exam/student/lesson/${lessonId}/exams/${subjectId}/tests`);


    this.commit('SET_TEST_LIST', response.data);
  }
  async loadSubjectStats({ subjectId }: { subjectId: number }) {
    const { data } = await axios.get(`/content/practice/curriculumsubject/${subjectId}/statistics`);
    this.commit('SET_SUBJECT_STATS', data);
  }
  updateStudentAnswer({ id, value, type }: {
    id: number;
    value: any;
    type?: string;
  }) {
    if (this.state.studentAnswerList[id]) {
      this.commit('UPDATE_TEST_ANSWER', { id, value });
    }

    if (type === 'type6') {
      LSTests.set(id, value);
    }
  }
  updateStudentAttaches({ id, value }: { id: number; value: any }) {
    if (this.state.studentAnswerList[id]) {
      this.commit('UPDATE_TEST_ATTACHES', { id, value });
    }
  }
  // TODO: Удалить этот метод после того, как сделаем норм отправку на бэк в тест тип 9
  updateStudentTestResult({ id, value }: { id: number; value: any }) {
    this.commit('UPDATE_STUDENT_TEST_RESULT', { id, value });
  }
  updateAnswerDisableStatus({ id, disabled }: { id: number; disabled: boolean }) {
    this.commit('UPDATE_TEST_DISABLE', { id, disabled });
  }
  tryTestAgain({ id }: { id: number }) {
    this.commit('DELETE_STUDENT_TEST_RESULT', { id });
  }
  async sendTestAnswer({ themeId, testVersionId, spentTime }: {
    themeId: number,
    testVersionId: number;
    spentTime: number;
  }) {
    const { type } = this.getters.currentTestById(testVersionId);
    const { answer, attaches } = this.state.studentAnswerList[testVersionId];

    const isSelfCheckType = selfCheck({ type });
    const preparedAnswer = isSelfCheckType
      ? {
        data: answer[0]?.value || '',
        selfMark: answer[0]?.selfMark || null,
      }
      : answer.map((item: any) => item.value);

    const payload = {
      // attaches,
      answer: preparedAnswer,
      spentTime,
    };

    const { data } = await api.v1.tests.sendTaskAnswer(themeId, testVersionId, payload);
    // TODO поменять роут
    // const { data: studentTestResult } = await axios.post(`/content/practice/curriculumsubject/${subjectId}/tests/${testId}`, data);

    // this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testVersionId, value: studentTestResult });
    this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testVersionId, value: data });
    this.commit('UPDATE_STUDENT_LIST_ANSWER', { id: testVersionId });
    this.commit('SPEND_ATTEMPT', { id: testVersionId });

    // TODO testType заменил на type. Корректно ли?
    if (type === 'type6') {
      LSTests.remove(testVersionId);
    }
  }
  // async showTestAnswer({ subjectId, lessonId, testId, spentTime }: {
  //   subjectId: number;
  //   lessonId: number;
  //   testId: number;
  //   spentTime: number;
  // }) {
  //   /*
  //     Костыль для кнопки узнать ответ, при правильном ответе:
  //     Если есть правильный ответ, то мы на клиенте добавляем флаг showSolution: true
  //     для того, чтобы ход решения( поле solution в тесте ) был виден.
  //     То есть смотрим правильный ли ответ, если да, то запроса на сервер не будет.
  //     Запрос для записи в базу - что ответ был просмотрен.
  //   */
  //   const updatedTest = this.getters.currentTestById(testId);
  //   const isTestCorrect = isTestAnsweredTrue(updatedTest) || isTestTeacherCheck(updatedTest);
  //
  //   if (isTestCorrect) {
  //     const updatedStudentTestResult = { ...updatedTest.testResult, showSolution: true };
  //
  //     this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testId, value: updatedStudentTestResult });
  //     return;
  //   }
  //   /* Конец костыля, удалить по возможности */
  //
  //   const postData = {
  //     showSolution: true,
  //     lessonId,
  //     spentTime,
  //   };
  //
  //   await axios.post(`/content/practice/curriculumsubject/${subjectId}/tests/${testId}`, postData);
  //   const { data } = await axios.get(`/content/practice/curriculumsubject/${subjectId}/tests/${testId}`);
  //   const { testAnswers, type, testResult } = data;
  //
  //   const studentTestResultLike = testAnswers.length && !checkboxOrRadio({ type })
  //     ? getResultFromRightAnswers(data)
  //     : testResult;
  //
  //   const valueToUpdate = studentTestResultLike || { showSolution: true, result: 'false' };
  //
  //   this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testId, value: valueToUpdate });
  //   this.commit('UPDATE_STUDENT_LIST_ANSWER', { id: testId });
  // }
}
