export enum UserRole {
  SUPER_ADMIN = 'super_administrator',
  ADMIN = 'administrator',
  INNOPOLIS_STUDENT = 'innopolis_student',
  INNOPOLIS_TEACHER = 'innopolis_teacher',
  INNOPOLIS_VERIFICATOR = 'innopolis_verificator',
}

export enum InnopolisApprovalStatus {
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  DELETED = 'DELETED',
  CHANGE_EDUCATIONAL_INSTITUTION = 'CHANGE_EDUCATIONAL_INSTITUTION',
  ADD_EDUCATIONAL_INSTITUTION = 'ADD_EDUCATIONAL_INSTITUTION',
  UPDATE_ACTUALITY_EDUCATIONAL_DATA = 'UPDATE_ACTUALITY_EDUCATIONAL_DATA',
  GRADUATE = 'GRADUATE',
  NONE = 'NONE',
}
