<template>
  <div class="m-files">
    <validation-provider
      ref="files"
      v-slot="{ errors }"
      :rules="rules"
    >
      <div class="m-files__control">
        <input
          :id="inputId"
          ref="input"
          :name="name"
          :disabled="!canAttach"
          type="file"
          data-test-id="uploadFileInput"
          class="m-files__input"
          @change="uploadFile"
        >
        <slot
          name="btn"
          v-bind="{ canAttach, inputId }"
        >
          <label
            :for="inputId"
            class="m-files__add-btn"
            :class="{ 'm-files__add-btn_disabled': !canAttach }"
          >
            <SvgIcon
              data="@icons/LMSIcons/clip.svg"
              class="m-files__btn-icon"
            />
          </label>
          <span
            v-if="label"
            class="m-files__label"
            v-text="label"
          />
        </slot>
      </div>
      <p class="m-files__error-message">{{ errors[0] }}</p>
    </validation-provider>
    <m-files-list
      :files="files"
      :name="fileName"
      :url="fileUrl"
      :disabled="disabled"
      class="m-files__list"
      @removeFile="removeFile"
    />
  </div>
</template>

<script>
export default {
  name: 'MFiles',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'm-files-input',
    },
    label: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: 'name',
    },
    fileUrl: {
      type: String,
      default: 'url',
    },
    limit: {
      type: [Number, String],
      default: null,
    },
    maxSize: {
      type: Number,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
    /**
     * Функция для загрузки файлов.
     *
     * @param {object} event - объект события инпута при добавлении файла
     * @return {object} Объект с параметрами name и url
     */
    uploadFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      fileList: [],
    };
  },
  computed: {
    files: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    inputId() {
      return `m-files-input-${this._uid}`;
    },
    canAttach() {
      const limitExceeded = this.limit != null ? this.files.length >= +this.limit : false;
      return !this.sending && !this.disabled && !limitExceeded;
    },
    totalSize() {
      return this.fileList.reduce((acc, current) => {
        if (!current) return;
        return acc + current.size;
      }, 0);
    },
  },
  methods: {
    convertMaxSize() {
      return this.maxSize / 1048576;
    },
    async uploadFile(event) {
      try {
        this.sending = true;
        const { valid } = await this.$refs.files.validate(event);

        if (!valid) return;
        this.fileList.push(event.target.files[0]);

        if (this.totalSize > this.maxSize) {
          this.fileList.pop();
          this.$mNotify({
            title: 'Ошибка загрузки',
            text: `Максимальный размер всех загружаемых файлов: ${mthis.convertMaxSize()} МБ`,
          });
          return;
        }

        const file = await this.uploadFunction(event);
        if (!file) return;
        this.files = [...this.files, file];
      } catch (err) {
        this.handleError({ err });
      } finally {
        this.sending = false;
      }
    },
    removeFile(fileUrl) {
      const idx = this.files.findIndex(file => file.filePath === fileUrl || file.url === fileUrl);
      this.fileList.splice(idx, 1);
      /* Принудительное очищение значения value для повторного прикрепления удалённого файла */
      this.$refs.input.value = '';

      const filesCopy = this.files;
      filesCopy.splice(idx, 1);
      this.files = filesCopy;
    },
    reset() {
      this.fileList = [];
      this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.m-files {
  &__control {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 1px solid $color-main;
    border-radius: 6px;
    cursor: pointer;

    &_disabled {
      border-color: $m-gray !important;
      cursor: default;

      & .m-files__btn-icon {
        fill: $m-gray !important;
      }
    }
  }

  &__btn-icon {
    fill: $color-main;
    height: 22px;
    width: 22px;
  }

  &__label {
    padding-left: 8px;
    font-size: 13px;
    color: $m-text-light;
  }

  &__input {
    display: none;
  }

  &__error-message {
    color: $m-light-red;
  }
}
</style>
