<template>
  <div
    :class="popupClasses"
    class="m-popup"
  >
    <h3
      v-if="hasHeader"
      class="m-popup__header"
    >
      <slot name="header"/>
    </h3>
    <div class="m-popup__body">
      <slot/>
    </div>
    <div
      v-if="hasFooter"
      class="m-popup__footer"
    >
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MPopup',
  props: {
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    hasArrow: {
      type: Boolean,
      default: true,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    popupClasses() {
      return {
        'm-popup_has-arrow': this.hasArrow,
        'm-popup_top': this.top,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.m-popup {
  min-width: 70px;
  background-color: #fff;
  border: 1px solid $m-gray;
  border-radius: 6px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .1);
  position: absolute;
  z-index: 2;
  top: 100%;

  &_has-arrow {
    margin-top: 15px;
    &:after {
      content: "";
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-left: 1px solid $m-gray;
      border-top: 1px solid $m-gray;
      border-radius: 3px;
      transform: rotate(45deg);
      position: absolute;
      z-index: 1;
      right: 43px;
      top: -10px;
    }
  }

  &_top {
    top: auto;
    bottom: 100%;
    &.m-popup_has-arrow {
      margin-top: 0;
      margin-bottom: 15px;
      &:after {
        top: auto;
        bottom: -10px;
        transform: rotate(225deg);
      }
    }
  }

  &__header {
    padding: 12px;
    font-weight: 500;
    font-size: 15px;
    border-bottom: 1px solid $m-light-gray;
    margin: 0;
  }

  &__body {
    padding: 10px 0;
    position: relative;
    z-index: 2;
  }

  &__footer {
    padding: 15px 12px 17px;
    border-top: 1px solid $m-light-gray;
  }
}
</style>
