import { HalfCircleSpinner as Spinner } from 'epic-spinners';
import { getBaseColor } from '@/assets/bootstrap/themeLoader';
import { h } from 'vue'

export default {
  name: 'LoadingSpinner',
  props: {
    animationDuration: {
      type: [String, Number],
      default: 1000,
    },
    size: {
      type: [String, Number],
      default: 50,
    },
  },
  render() {
    return h(Spinner, {
      class: 'loading-spinner',
      // props: {
      //   animationDuration: +this.animationDuration,
      //   size: +this.size,
      //   color: getBaseColor(),
      // },
      animationDuration: +this.animationDuration,
      size: +this.size,
      color: getBaseColor(),
    });
  },
};
