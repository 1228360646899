<template>
  <div class="m-video">
    <iframe
      :src="url"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      controls
      width="100%"
      height="480"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
