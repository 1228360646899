export default {
  methods: {
    handleError({ err, errorMessage, delay = 3000 }) {
      console.error(err);
      if (err.response) {
        this.handleServerError(err.response, errorMessage, delay);
      } else {
        this.handleClientError(err, delay);
      }
    },
    /**
     * Обработка серверной ошибки
     * @param {Object} res - Объект, содержащий нативный response
     */
    handleServerError(res, errorMessage, delay) {
      const title = 'Ошибка';
      const text = errorMessage;

      this.$mNotify({
        title,
        text,
        delay,
      });
    },
    /**
     * Обработка клиентской ошибки
     * @param {Object} error - Объект, содержащий нативный объект класса Error
     */
    handleClientError(error, delay) {
      if (error.message === 'Network Error') {
        this.$mNotify({
          title: 'Ошибка',
          text: 'Ошибка подключения к интернету',
          delay,
        });
      } else {
        this.$mNotify({
          title: 'Ошибка',
          text: 'Что-то пошло не так',
          delay,
        });
      }
    },
    showValidationErrorCommon() {
      this.$mNotify({
        title: 'Поля заполнены некорректно',
        text: 'Исправьте поля, выделенные красным, и повторите попытку',
      });
    },
  },
};
