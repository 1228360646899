<template>
  <span
    :style="counterStyles"
    class="m-counter"
  >
    {{ formattedCount }}
  </span>
</template>

<script>
export default {
  name: 'MCounter',
  props: {
    background: {
      type: String,
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      maxCount: 999,
    };
  },
  computed: {
    counterStyles() {
      return {
        backgroundColor: this.background,
      };
    },
    formattedCount() {
      return this.count <= this.maxCount ? this.count : `${this.maxCount}+`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-counter {
    font-family: "WorkSans", sans-serif;
    font-weight: 500;
    font-size: 11px;
    color: #fff;
    height: 16px;
    min-width: 16px;
    border-radius: 8px;
    background-color: $m-light-red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
  }
</style>
