<template>
  <div
    :class="classes"
    class="m-radio"
  >
    <input
      :id="id"
      :name="name"
      :checked="checked"
      :value="value"
      :disabled="disabled"
      type="radio"
      class="m-radio__input"
      :data-test-id="`mRadioInput${dataTestPostfix}`"
      @change="onChange"
    >
    <label
      :for="id"
      class="m-radio__label"
      :data-test-id="`mRadioLabel${dataTestPostfix}`"
    >
      <div
        class="m-radio__text"
        :data-test-id="`mRadioTextSlot${dataTestPostfix}`"
      >
        <slot/>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: 'option',
    },
    name: {
      type: String,
      default: 'option',
    },
    value: {
      type: [String, Number],
      default: 'val',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
      validator: value => ['success', 'error', 'marked'].includes(value),
    },
    dataTestPostfix: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    checked() {
      return this.modelValue === this.value;
    },
    classes() {
      return [
        { 'm-radio_checked': this.checked },
        `m-radio_status_${this.status}`,
      ];
    },
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-radio {
    display: flex;
    align-items: center;
    min-height: 24px;
    &__input {
      display: none;
      &:disabled {
        + .m-radio__label {
          cursor: default;
          &:before {
            border-color: $m-light-gray;
          }
        }
      }
    }
    &_checked {
      & .m-radio__input {
        + .m-radio__label {
          color: #000;
          &:after {
            opacity: 1;
          }
        }
      }
    }
    &__label {
      color: $m-text-light;
      position: relative;
      transition: color .3s ease;
      display: block;
      padding-left: 26px;
      cursor: pointer;
      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid #000;
        left: 0;
      }
      &:after {
        width: 12px;
        height: 12px;
        background-color: #000;
        border-radius: 50%;
        left: 0;
        margin-left: 5px;
        opacity: 0;
        transition: all .2s ease;
      }
    }
    &__text {
      padding: 6px;
      border-radius: 8px;
    }
    &_status {
      &_success {
        .m-radio__label {
          &:before {
            border-color: $m-light-green !important;
          }
          &:after {
            background-color: $m-light-green;
          }
        }
      }
      &_error {
        .m-radio__label {
          &:before {
            border-color: $m-light-red !important;
          }
          &:after {
            background-color: $m-light-red;
          }
        }
      }
      &_marked {
        .m-radio__label {
          &:before {
            border-color: $m-light-green !important;
          }
          & .m-radio__text {
            background-color: $m-very-light-green;
          }
        }
      }
    }
  }
</style>
