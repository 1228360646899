<template>
  <svg
    :height="radius * 2"
    :width="radius * 2"
    :style="{ transform: `rotate(${rotate}deg)` }"
  >
    <circle
      :stroke="backColor"
      :stroke-width="strokeBaseLine || stroke"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
    <circle
      :stroke="fillColor"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset: strokeDashoffset }"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>

<script>
export default {
  name: 'MProgressCircle',
  props: {
    radius: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    stroke: {
      type: Number,
      default: 4,
    },
    strokeBaseLine: {
      type: Number,
      default: 0,
    },
    backColor: {
      type: String,
      default: '#000000',
    },
    fillColor: {
      type: String,
      default: '#FFFFFF',
    },
    rotate: {
      type: [String, Number],
      default: -90,
    },
  },
  data() {
    const normalizedRadius = this.radius - this.stroke / 2;
    const circumference = normalizedRadius * Math.PI * 2;

    return {
      normalizedRadius,
      circumference,
    };
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - this.value / 100 * this.circumference;
    },
  },
};
</script>
