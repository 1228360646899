import { RouteName, LayoutName, RouteParam } from '@/enums';
import { validateAuthStatusParam, removeHash, removeQueryParams } from '@/router/guards';

import type { RouteRecordRaw } from 'vue-router';

const _403 = () => import(
  /* webpackChunkName: "403-page" */
  '@/views/errors/AccessForbidden.vue'
);
const _404 = () => import(
  /* webpackChunkName: "404-page" */
  '@/views/errors/PageNotFound.vue'
);
const _500 = () => import(
  /* webpackChunkName: "500-page" */
  '@/views/errors/InternalServerError.vue'
);

export const errorsRoutes: RouteRecordRaw[] = [
  {
    path: `/403/:${RouteParam.AUTH_STATUS}?`,
    name: RouteName.ERROR_403,
    component: _403,
    beforeEnter: [validateAuthStatusParam],
    meta: {
      layout: LayoutName.ERROR,
    },
  },
  {
    path: '/404',
    name: RouteName.ERROR_404,
    component: _404,
    beforeEnter: [removeQueryParams, removeHash],
    meta: {
      layout: LayoutName.ERROR,
    },
  },
  {
    path: '/500',
    name: RouteName.ERROR_500,
    component: _500,
    meta: {
      layout: LayoutName.ERROR,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => ({ name: RouteName.ERROR_404 }),
  },
];
