<template>
  <table
    v-if="items && items.length"
    :class="{ 'm-table_bordered': bordered }"
    class="m-table"
  >
    <thead>
      <tr>
        <th
          v-for="(header, index) in headers"
          :key="index"
          class="m-table__th"
          :style="{ textAlign: header.align, width: header.width }"
        >
          <slot
            :name="`head-${header.field}`"
            :header="header"
          >
            {{ header.text }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="`table-row-${index}`"
      >
        <td
          v-for="(header, index2) in headers"
          :key="`table-data-${index2}`"
          class="m-table__col"
          :style="{ textAlign: header.align, width: header.width }"
        >
          <slot
            :name="header.field"
            :item="item"
            :index="index"
          >
            <span
              v-if="valueIsSimple(item[header.field])"
              class="m-table__text"
            >
              {{ header.field === 'index' ? index + 1 : item[header.field] }}
            </span>
            <span
              v-else-if="item[header.field].type === 'text'"
              :class="`m-table__text_color_${item[header.field].color}`"
              class="m-table__text"
            >
              {{ item[header.field].text }}
            </span>
            <m-link
              v-else
              :href="typeof (item[header.field].url) === 'string' ? item[header.field].url : '#'"
              :color="item[header.field].color"
              @click="$emit('clickToLink', { event: $event, data: item[header.field].url })"
            >
              {{ item[header.field].text }}
            </m-link>
          </slot>
        </td>
      </tr>
      <tr v-if="total">
        <td
          v-for="(header, index2) in headers"
          :key="`table-total-data-${index2}`"
          class="m-table__col m-table__col_total"
        >
          <span
            v-if="valueIsSimple(total[header.field])"
            class="m-table__text"
          >
            {{ header.field === 'index' ? '' : total[header.field] }}
          </span>
          <span
            v-else-if="total[header.field].type === 'text'"
            :class="`m-table__text_color_${total[header.field].color}`"
            class="m-table__text"
          >
            {{ total[header.field].text }}
          </span>
          <m-link
            v-else
            :href="total[header.field].url"
            :color="total[header.field].color"
          >
            {{ total[header.field].text }}
          </m-link>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    v-else
    class="v-table__empty-placeholder"
    v-text="'Нет данных для отображения'"
  />
</template>

<script>
export default {
  name: 'MTable',
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Object,
      default: null,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    valueIsSimple(value) {
      return !value
      || typeof (value) === 'string'
      || typeof (value) === 'number';
    },
  },
};
</script>

<style lang="scss" scoped>
.m-table {
  &__th {
    font-weight: 500;
    padding: 4px 4px 8px;
    text-align: left;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &__col {
    padding: 4px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }

    &_total {
      border-top: 1px solid $m-gray;
      font-weight: bold;
    }
  }

  &__text {
    &_color {
      &_gray {
        color: $m-dark-gray;
      }
      &_light-gray {
        color: $m-gray;
      }
      &_green {
        color: $m-light-green;
      }
      &_red {
        color: $m-light-red;
      }
    }
  }

  &_bordered {
    .m-table__th {
      border-bottom: 1px solid $m-gray;
      color: #c4c4c4;
      padding: 12px;
    }

    .m-table__col {
      padding: 12px;
    }
  }
}
</style>
