<script>
import { h } from 'vue';

export default {
  name: 'MTitle',
  props: {
    level: {
      type: [String, Number],
      default: 1,
    },
    dataTestId: {
      type: String,
      default: null,
    },
    noUpper: {
      type: Boolean,
      default: false,
    },
  },
  render() {
    return h(
      `h${+this.level}`,
      {
        class: [
          'm-title',
          `m-title_level_${+this.level}`,
          { 'm-title_no-upper': this.noUpper },
        ],
        attrs: {
          'data-test-id': this.dataTestId,
        },
      },
      this.$slots.default,
    );
  },
};
</script>

<style lang="scss" scoped>
.m-title {
  line-height: 1.2;

  &_level {
    &_1 {
      font-size: 21px;
      text-transform: uppercase;
    }

    &_2 {
      font-size: 17px;
    }

    &_3 {
      font-size: 15px;
      font-weight: 500;
    }
  }

  &_no-upper {
    text-transform: none;
  }
}
</style>
