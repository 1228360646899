<template>
  <div
    :class="hintClasses"
    class="m-hint"
  >
    <div
      v-if="closable"
      class="m-hint__close-btn"
      @click.stop="$emit('close')"
    >
      <SvgIcon
        data="@icons/LMSIcons/close.svg"
        class="m-hint__close-ico"
      />
    </div>
    <div
      class="m-hint__text"
      :style="customTextStyle"
    >
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MHint',
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'blue',
      validator(value) {
        return ['blue', 'white', 'red', 'gray'].includes(value);
      },
    },
    position: {
      type: String,
      default: 'right',
      validator(value) {
        return ['top', 'right', 'bottom', 'left'].includes(value);
      },
    },
    customTextStyle: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    hintClasses() {
      return [
        `m-hint_color_${this.color}`,
        `m-hint_position_${this.position}`,
        { 'm-hint_closable': this.closable },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.m-hint {
  position: absolute;
  padding: 16px;
  border-radius: 6px;
  max-width: 350px;
  width: max-content;
  z-index: 99;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    transform: rotate(-45deg);
  }

  &_closable {
    padding: 16px 24px 16px 16px;
  }

  &_position {
    &_left {
      left: auto;
      right: calc(100% + 15px);
      top: 50%;
      transform: translate(0, -50%);

      &:before {
        left: auto;
        right: -9px;
        top: 50%;
        margin-top: -8px;
      }
    }

    &_right {
      left: calc(100% + 15px);
      top: 50%;
      transform: translateY(-50%);

      &:before {
        left: -9px;
        top: 50%;
        margin-top: -8px;
      }
    }

    &_top {
      top: auto;
      left: 50%;
      bottom: calc(100% + 15px);
      transform: translateX(-50%);

      &:before {
        top: auto;
        left: 50%;
        bottom: -9px;
        margin-left: -8px;
      }
    }

    &_bottom {
      left: 50%;
      top: calc(100% + 15px);
      transform: translateX(-50%);

      &:before {
        top: -9px;
        left: 50%;
        margin-left: -8px;
      }
    }
  }

  &_color {
    &_blue {
      color: #fff;
      background-color: #0080fc;

      &:before {
        background-color: #0080fc;
      }
    }

    &_white, &_red, &_gray {
      background-color: #fff;
      border: 1px solid $m-gray;

      &.m-hint_position {
        &_left {
          &:before {
            border-right: 1px solid $m-gray;
            border-bottom: 1px solid $m-gray;
          }
        }

        &_right {
          &:before {
            border-left: 1px solid $m-gray;
            border-top: 1px solid $m-gray;
          }
        }

        &_top {
          &:before {
            border-left: 1px solid $m-gray;
            border-bottom: 1px solid $m-gray;
          }
        }

        &_bottom {
          &:before {
            border-right: 1px solid $m-gray;
            border-top: 1px solid $m-gray;
          }
        }
      }

      &:before {
        background-color: #fff;
      }

      .m-hint__close-ico {
        fill: $m-text;
      }
    }

    &_white {
      .m-hint__text {
        color: #000;
      }
    }

    &_red {
      .m-hint__text {
        color: $m-light-red;
      }
    }

    &_gray {
      .m-hint__text {
        color: $m-text-light;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 3px;
    line-height: 1;
    cursor: pointer;

    &:hover .m-hint__close-ico {
      fill: $color-main;
    }
  }

  &__close-ico {
    fill: rgba(255, 255, 255, .8);
    width: 8px;
    height: 8px;
    transition: fill 0.2s;
  }

  &__text {
    font-size: 13px;
    line-height: 15px;
  }
}
</style>
