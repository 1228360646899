
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MContainer',
  inheritAttrs: false,
  props: {
    componentIs: {
      type: String,
      default: 'section'
    },
    adaptive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        adaptive: this.adaptive,
      };
    }
  }
})
