import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon';
import { createApp } from 'vue';
import FloatingVue from 'floating-vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import 'floating-vue/dist/style.css';
import '@yzfe/svgicon/lib/svgicon.css';
import Maxify from '@/uikit/maxify';
import Adaptive from '@/plugins/adaptive';
import errorHandler from '@/mixins/errorHandler';
import { storeInstance } from '@/store/vuex';
import MNotify from '@/plugins/MNotify';

// TODO подумать над типизацией
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import clickOutside from '@/directives/click-outside';

const pinia = createPinia();
const app = createApp(App);

app.directive('click-outside', clickOutside);

app.mixin(errorHandler);

app
  .use(pinia)
  .use(storeInstance)
  .use(router)
  .use(Maxify)
  .use(MNotify)
  .use(Adaptive, {
    breakpoints: {
      mobile: 640,
    },
    throttle: 66,
  })
  .use(VueSvgIconPlugin, { tagName: 'SvgIcon' })
  .use(FloatingVue, {
    themes: {
      'info-dropdown': {
        $extends: 'dropdown',
      },
      'info-tooltip': {
        $extends: 'dropdown',
      },
    },
  });

app.mount('#app');
