<template>
  <div
    class="m-progress-bar"
    :style="{
      height: `${Number(height) || 4}px`,
      background: progressColor,
    }"
  />
</template>

<script>
import { getBaseColor } from '@/assets/bootstrap/themeLoader';

export default {
  name: 'MProgressBar',
  props: {
    height: {
      type: [Number, String],
      default: 4,
    },
    color: {
      type: [String, Array],
      default: getBaseColor(),
    },
    backColor: {
      type: String,
      default: '#DDD',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    maxValue: {
      type: [Number, String],
      default: 100,
    },
  },
  computed: {
    progressColor() {
      let currentColor = getBaseColor();
      const value = Math.round(Number(this.value) / Number(this.maxValue) * 100);

      if (Array.isArray(this.color)) {
        this.color.forEach((c) => {
          if (this.value >= c.value) {
            currentColor = c.color;
          }
        });
      } else {
        currentColor = this.color;
      }

      return this.value === this.maxValue
        ? currentColor
        : `linear-gradient(to right, ${currentColor} ${value}%, ${this.backColor} ${value}%, ${this.backColor} 100%)`;
    },
  },
};
</script>
