<template>
  <router-link
    v-if="isRouterLink"
    ref="link"
    :class="linkClasses"
    :to="href"
    class="m-link"
    @click.native="clickHandler"
  >
    <slot/>
  </router-link>
  <a
    v-else
    ref="link"
    :href="href"
    :target="target"
    :class="linkClasses"
    class="m-link"
    @click="clickHandler"
  >
    <slot/>
  </a>
</template>

<script>
export default {
  name: 'MLink',
  props: {
    href: {
      type: [String, Object],
      default: '#',
    },
    target: {
      type: String,
      default: '_self',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'list-item', 'button', 'classic'].includes(value);
      },
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return [null, 'orange', 'black', 'blue'].includes(value);
      },
    },
    isRouterLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkClasses() {
      let classes = `m-link_type_${this.type}`;
      classes += this.disabled ? ' m-link_disabled' : '';
      classes += this.color ? ` m-link_color_${this.color}` : '';
      classes += this.fill && this.type === 'button' ? ' m-link_type_button-filled' : '';

      return classes;
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit('click', event);

      if (!this.$refs.link) return;

      if (this.isRouterLink) {
        this.$refs.link.$el.blur();
      } else {
        this.$refs.link.blur();
      }
    },
  },
};
</script>

<style lang="scss">
  .m-link {
    color: $m-text;
    transition: all .3s ease;
    .svg-icon {
      transition: all .3s ease;
    }
    &_disabled {
      cursor: default;
      pointer-events: none;
      color: $m-gray;
    }
    &_type {
      &_default {
        text-decoration: underline;
        &:hover {
          color: $color-lighten;
          .svg-icon {
            fill: $color-lighten;
          }
        }
        &:focus {
          color: $color-main;
          outline: none;
        }
      }
      &_list-item {
        text-decoration: none;
        display: block;
        &:hover {
          background-color: $m-light-gray;
        }
        &:focus {
          background-color: $m-gray;
        }
      }
      &_button {
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 999px;
        color: $color-main;
        border: 1px solid $color-main;
        background-color: #fff;
        min-height: 32px;
        padding: 5px 20px;
        &-filled {
          color: #fff;
          background-color: $color-main;
          text-decoration: none;
        }
        &:hover {
          background-color: $color-lighten;
          border-color: $color-lighten;
          color: #fff;
        }
        &:focus {
          background-color: $color-main;
          border-color: $color-main;
          color: #fff;
          outline: none;
        }
      }
      &_classic {
        text-decoration: none;
        &:hover {
          color: $color-lighten;
        }
        &:focus {
          color: $color-main;
          outline: none
        }
      }
    }
    &_color {
      &_orange {
        color: $color-main;
      }

      &_blue {
        color: $m-blue;
        border-color: $m-blue;

        &:focus,
        &:hover {
          background: $m-blue;
          border-color: $m-blue;
        }
      }
    }
  }
</style>

<style lang="scss">
  .m-link {
    &_type {
      &_list-item {
        padding: 7px 10px;
      }
    }
  }
</style>
