// Тесты без решения
const noSolutionTestTypes = ['type10'];

// Тесты с самопроверкой
const selfCheckTestTypes = ['type9'];

// Тесты с чекбоксами или радиокнопками
const checkboxOrRadioTypes = ['type1', 'type2'];

// Тесты с текстовыми инпутами
const inputsTypes = ['type3', 'type5'];

// Тесты, у которых value не строка со значением ответа, а id ответа
const answerValueAsIdTypes = ['type1', 'type2', 'type4'];

const oneAttemptsTestTypes = ['type6', 'type9', 'type10'];

// Тесты, где в конце блок с комментарием и оценкой преподавателя
const testWithCommentAndMark = ['type6'];

// Типы тестов, где доступно автозаполнение
const testTypesWithAutofill = ['type1', 'type2', 'type3', 'type4', 'type5'];

// Статусы тестов для пагинации
export const testStates = {
  error: 'error',
  missing: 'missing',
  saved: 'saved',
  success: 'success',
  waiting: 'waiting',
  partially: 'partially',
};

// Статусы результатов тестов
export const testResults = {
  true: 'true',
  false: 'false',
  partially: 'partially',
  skip: 'skip',
  teacherCheck: 'teacher_check',
};

// Была ли попытка ответа от ученика
export const isTestTouchedByStudent = test => {
  return !!test.testResult && !test.isExam;
}

// Правильный ли ответ у ученика
export const isTestAnsweredTrue = test => test.testResult?.result === testResults.true;

// Неправильный ли ответ у ученика
export const isTestAnsweredFalse = test => test.testResult?.result === testResults.false;

// Частично правильный ответ у ученика
export const isTestAnsweredPartially = test => test.testResult?.result === testResults.partially;

// У studentTestResult.result статус skip
export const isTestAnsweredSkip = test => test.testResult?.result === testResults.skip;

// Есть какой-либо ответ и он проверен
export const isTestAnsweredAndChecked = test => isTestAnsweredTrue(test)
                                             || isTestAnsweredFalse(test)
                                             || isTestAnsweredPartially(test);

// Тест отправлен на проверку учителю
export const isTestTeacherCheck = test => test.testResult?.result === testResults.teacherCheck;

// Решение показано после того, как был верный ответ
export const addRightAnswerBeforeShowSolution = test => test.testResult?.addRightAnswerBeforeShowSolution;

// Ученик узнал ответ
export const isShowSolution = test => test?.testResult?.showSolution;

// Есть ход решения у тестов
export const hasSolutionSteps = test => !!test.solution;

// Есть ответ ученика
export const hasAnswer = test => test.testResult?.answer.length;

// Есть рикрепленные файлы ученика
export const hasAttaches = test => test.testResult?.id && test.testResult?.attaches?.length;

// Тест без эталонного ответа
// export const testWithoutReferenceAnswer = test => !test.educationTestAnswers.length && !hasSolutionSteps(test);
export const testWithoutReferenceAnswer = test => !test.testAnswers.length && !hasSolutionSteps(test);

// Типы тестов без решения
export const noSolution = ({ type }) => noSolutionTestTypes.includes(type);

// Попыток для решения теста больше 0
export const hasAttemps = ({ attemptsCountLeft }) => attemptsCountLeft > 0;

// Типы тестов с самопроверкой
export const selfCheck = ({ type }) => selfCheckTestTypes.includes(type);

// Типы тестов с одной попыткой
export const oneAttemptType = ({ type }) => oneAttemptsTestTypes.includes(type);

// Показывать ли радиокнопки самооценки
export const isSelfCheckVisible = test => selfCheck(test)
                                      && !testWithoutReferenceAnswer(test)
                                      && !isShowSolution(test)
                                      && test.testResult?.percent !== undefined;

// Типы тестов, у которых в качестве значения не строки, а id
export const answerValueAsId = ({ type }) => answerValueAsIdTypes.includes(type);

// Типы тестов с чекбоксами или радиокнопками
export const checkboxOrRadio = ({ type }) => checkboxOrRadioTypes.includes(type);

// Типы тестов с текстовыми инпутами
export const isInputType = ({ type }) => inputsTypes.includes(type);

// Типы тестов с комментарием и оценкой преподавателя в конце
export const isTestWithCommentAndMark = ({ type }) => testWithCommentAndMark.includes(type);

// Тип с комментарием преподавателя и комментарий написан и сохранен
export const hasTeacherComment = test => isTestWithCommentAndMark({ type: test.type }) && test.testResult?.teacherComment;

// Типы для которых доступно автозаполнение ответов
export const isAutofillAvailable = ({ type }) => testTypesWithAutofill.includes(type);
