import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { Mutations } from 'vuex-smart-module';
import { getAnswerListItem, mapTestToAnswerList } from './helpers';
import type { CorrectionsState } from './state';

export class CorrectionsMutations extends Mutations<CorrectionsState> {
  SET_CORRECTIONS_LIST(list: any) {
    this.state.correctionsList = list;
  }
  SET_TEST_LIST(list: any) {
    this.state.testList = list;
    this.state.studentAnswerList = mapTestToAnswerList(list);
  }
  SET_SUBJECT(payload: any) {
    this.state.subject = payload;
  }
  UPDATE_TEST_ANSWER({ id, value }: { id: number; value: any }) {
    this.state.studentAnswerList[id].answer = value;
  }
  UPDATE_TEST_DISABLE({ id, disabled }: { id: number; disabled: boolean }) {
    this.state.studentAnswerList[id].disabled = disabled;
  }
  UPDATE_STUDENT_TEST_RESULT({ id, value }: { id: number; value: any }) {
    const updatedTestIndex = this.state.testList.findIndex((t: any) => t.id === id);
    const updatedTest = cloneDeep(this.state.testList[updatedTestIndex]);

    // Замена объекта теста на обновленный
    updatedTest.studentTestResult = value;
    // Vue.set(this.state.testList, updatedTestIndex, updatedTest); // во vue3 не работает
    this.state.testList[updatedTestIndex] = updatedTest;
  }
  UPDATE_STUDENT_LIST_ANSWER({ id }: { id: number }) {
    const { studentTestResult } = this.state.testList.find((t: any) => t.id === id);
    this.state.studentAnswerList[id] = getAnswerListItem(studentTestResult);
  }
  DELETE_STUDENT_TEST_RESULT({ id }: { id: number }) {
    const testToModify = this.state.testList.find((t: any) => t.id === id);
    // Удаляем только на фронте поле studentTestResult для возможности снова послать тест
    // Для правильно отвеченных тестов бэк вернет ошибку(400)
    testToModify.studentTestResult = null;
  }
}
