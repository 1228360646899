
import { PropViewValues, PropSizeValues } from './mButton.enums';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MButton',
  props: {
    type: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: PropViewValues.FILL,
      validator: value => Object.values(PropViewValues).includes(value as any),
    },
    size: {
      type: String,
      default: PropSizeValues.NORMAL,
      validator: value => Object.values(PropSizeValues).includes(value as any),
    },
    smallText: {
      type: Boolean,
    },
    lowercased: {
      type: Boolean,
    },
    underline: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    dataTestId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      refButton: null as HTMLButtonElement | null
    }
  },
  computed: {
    classes() {
      return [
        `m-button_view_${this.view}`,
        `m-button_size_${this.size}`,
        { 'm-button_small-text': this.smallText },
        { 'm-button_lowercased': this.lowercased },
        { 'm-button_underline': this.underline },
      ];
    },
  },
  methods: {
    clickHandler() {
      if (this.disabled) return;
      (this.refButton as HTMLButtonElement).blur();
    }
  },
  mounted() {
    this.refButton = this.$refs.button as HTMLButtonElement
  }
})
