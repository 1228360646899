<template>
  <ul class="m-tree">
    <li
      v-for="(chapter, index) in chaptersLocal"
      :key="chapter.id"
      :class="{ 'm-tree__item_active': chapter.id === currentId }"
      class="m-tree__item"
    >
      <h4
        v-if="!parentIndex"
        class="m-tree__title"
        @click="onClickFolder(chapter.id)"
      >
        <SvgIcon
          :dir="chapter.opened ? 'up' : 'left'"
          data="@icons/LMSIcons/arrow_bottom.svg"
          class="m-tree__arrow"
        />
        <span>
          {{ parentIndex + (index + 1) + '.' }} {{ chapter.title }}
        </span>
      </h4>
      <h5
        v-else-if="!!parentIndex && chapter.children && chapter.children.length"
        class="m-tree__subtitle"
        @click="onClickFolder(chapter.id)"
      >
        <SvgIcon
          :dir="chapter.opened ? 'up' : 'left'"
          data="@icons/LMSIcons/arrow_bottom.svg"
          class="m-tree__arrow m-tree__arrow_small"
        />
        {{ parentIndex }}{{ index + 1 }}. {{ chapter.title }}
      </h5>
      <m-link
        v-else
        href="#"
        type="classic"
        class="m-tree__link"
        @click.prevent="switchId(chapter.id)"
      >
        {{ parentIndex }}{{ index + 1 }}. {{ chapter.title }}
      </m-link>
      <m-transition>
        <m-tree
          v-if="chapter.children && chapter.children.length"
          v-show="chapter.opened"
          :chapters="chapter.children"
          :current-id="currentId"
          :parent-index="parentIndex + (index + 1) + '.'"
          is-nested
          class="m-tree_inside"
          @switch="switchId"
          @clickFolder="onClickFolder"
        />
      </m-transition>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MTree',
  props: {
    chapters: {
      type: Array,
      default: () => [],
    },
    currentId: {
      type: Number,
      default: 0,
    },
    parentIndex: {
      type: String,
      default: '',
    },
    isNested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedChapters: [],
      chapterIsFinded: false,
    };
  },
  computed: {
    chaptersLocal() {
      return this.chapters.filter(item => (item.children && item.children.length) || item.hasContent);
    },
  },
  created() {
    if (!this.isNested) {
      this.setChaptersWatcher();
    }
  },
  methods: {
    initOpenTree() {
      this.chapterIsFinded = false;

      this.computeOpenedFolders(this.chapters, this.currentId);
      this.openFoldersPath(this.openedChapters);
    },
    computeOpenedFolders(array, id) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].children && array[i].children.length) {
          this.openedChapters.push(array[i].id);
          this.computeOpenedFolders(array[i].children, id);
        } else if (array[i].id === id) {
          this.chapterIsFinded = true;
          break;
        }
      }

      if (!this.chapterIsFinded) {
        this.openedChapters.pop();
      }
    },
    openFoldersPath(openedChapters) {
      let currentChaptersArray = this.chaptersLocal;

      openedChapters.forEach((chapter, index) => {
        const openingFolderId = this.openedChapters[index];
        const openingFolder = currentChaptersArray.find(c => c.id === openingFolderId);
        if (openingFolder) {
          openingFolder['opened'] = true;
          currentChaptersArray = openingFolder.children;
        }
      });
    },
    onClickFolder(id) {
      const clickedFolderIndex = this.chaptersLocal.findIndex(chapter => chapter.id === id);

      if (clickedFolderIndex !== undefined) {
        this.chaptersLocal[clickedFolderIndex]['opened'] = !this.chaptersLocal[clickedFolderIndex].opened;
      }
    },
    setChaptersWatcher() {
      this.$watch('chapters', () => {
        this.initOpenTree();
      }, { immediate: true });
    },
    switchId(id) {
      if (id !== this.currentId) {
        this.$emit('switch', id);
      }
    },
  },
};
</script>

<style lang="scss">
  .m-tree {
    padding-left: 12px;
    background-color: #fff;
    &_inside {
      padding-left: 0;
    }
    &__title {
      font-size: 15px;
      line-height: 17px;
      font-weight: 500;
      position: relative;
      cursor: pointer;
    }
    &__subtitle {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
    }
    &__arrow {
      width: 10px !important;
      height: 8px !important;
      position: absolute;
      left: -16px;
      top: 50%;
      margin-top: -3px;
      transition: all .3s;
      &_small {
        width: 6px;
        height: 3px;
        left: -14px;
        margin-top: -1px;
      }
    }
    &__item {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 10px;
      &_active {
        a {
          user-select: none;
          color: $color-main;
        }
      }
    }
    &__link {
      display: block;
    }
  }
</style>
