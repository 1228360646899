<template>
  <button
    v-show="showScrollBtn"
    :disabled="disabled"
    class="m-scroll-btn"
    @click="scrollToElement"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'MScrollBtn',
  props: {
    element: {
      type: String,
      default: 'body',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstScreenHeight: window.innerHeight,
      showScrollBtn: false,
      scrollTarget: null,
    };
  },
  mounted() {
    this.initWindowScroll();
  },
  methods: {
    scrollToElement() {
      if (!this.scrollTarget) return;

      this.scrollTarget.scrollIntoView({ behavior: 'smooth' });
    },
    initWindowScroll() {
      this.scrollTarget = window.document.querySelector(this.element);
      window.addEventListener('scroll', this.checkWindowPosition);
    },
    checkWindowPosition() {
      if (window.pageYOffset > this.firstScreenHeight * 2) {
        this.showScrollBtn = true;
      } else {
        this.showScrollBtn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-scroll-btn {
    position: fixed;
    display: inline-block;
    font-weight: 400;
    padding: 0;
    font-size: 9px;
    line-height: 1;
    border: 0;
    border-radius: 6px;
    background-color: $color-main;
    cursor: pointer;
    transition: all .3s ease;
    min-width: 100px;
    color: #fff;
    box-shadow: 0 8px 14px rgba(255, 151, 102, .3);
    &:hover {
      background-color: $color-lighten;
      border-color: $color-lighten;
      color: #fff;
    }
    &:focus {
      background-color: $color-main;
      border-color: $color-main;
      color: #fff;
      outline: none;
    }
    &:disabled {
      border-color: $m-gray;
      pointer-events: none;
      cursor: default;
      &:hover {
        background-color: #fff;
      }
      &:focus {
        background-color: #fff;
      }
    }
  }
</style>
