import { Module } from 'vuex-smart-module';
import { DebtsGetters } from './getters';
import { DebtsActions } from './actions';
import { DebtsMutations } from './mutations';
import { DebtsState } from './state';

export default new Module({
  state: DebtsState,
  mutations: DebtsMutations,
  actions: DebtsActions,
  getters: DebtsGetters,
});
