<template>
  <div class="m-spoiler">
    <component
      :is="tag"
      :class="tagClass"
      class="m-spoiler__text"
      @click="toggleState"
    >
      <div class="m-spoiler__left">
        <SvgIcon
          v-if="icon"
          :data="`@icons/LMSIcons/${icon}.svg`"
          :class="{ 'm-spoiler__icon_hover-color-orange': highlightIconOnHover }"
          class="m-spoiler__icon"
        />
        {{ text }}
      </div>
      <SvgIcon
        :class="{ 'm-spoiler__text-icon-open': isOpen }"
        :style="{ width: chevronSize, height: chevronSize, fill: chevronColor }"
        :data="`@icons/LMSIcons/${chevronIcon}.svg`"
        class="m-spoiler__text-icon"
      />
    </component>
    <m-transition>
      <div v-if="isOpen">
        <slot/>
      </div>
    </m-transition>
  </div>
</template>

<script>
export default {
  name: 'MSpoiler',
  props: {
    text: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    tagClass: {
      type: String,
      default: '',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
    chevronIcon: {
      type: String,
      default: 'arrow_bottom',
    },
    chevronSize: {
      type: String,
      default: '',
    },
    chevronColor: {
      type: String,
      default: '',
    },
    noAnimation: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    highlightIconOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: null,
      show: true,
    };
  },
  created() {
    this.isOpen = this.opened;
  },
  methods: {
    toggleState() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-spoiler {
    &__left {
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      fill: $m-text;
      transition: fill .3s ease;
      margin: 0 10px 0 0;
    }

    &__text {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;

      &:hover .m-spoiler__icon_hover-color-orange {
        fill: $color-lighten;
      }

      &-icon {
        transition: .3s ease-in-out;

        &-open {
          transform: rotateX(180deg);
        }
      }
    }
  }
</style>
