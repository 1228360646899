import Notifications from './components/MNotify.vue';
import { emitter } from '@/utils/emitter';
import type { NotifyParams } from './types';

export default {
  install(app: any) {
    app.component('MNotify', Notifications);

    app.config.globalProperties.$mNotify = (params: NotifyParams) => {
      emitter.emit('add', params);
    };
  },
};
