import { Actions } from 'vuex-smart-module';
import { axios } from '@/services/api';

import type { DebtsGetters } from './getters';
import type { DebtsMutations } from './mutations';
import type { DebtsState } from './state';
import type { IDebts } from './state.types';

export class DebtsActions extends Actions<
DebtsState,
DebtsGetters,
DebtsMutations,
DebtsActions
> {
  async loadDebts(groupId: number) {
    const { data } = await axios.get<IDebts>(
      `/schedule/student/schedule/groups/${groupId}/debts`,
    );
    this.mutations.setDebts(data);
  }
}
