import _kebabCase from 'lodash/kebabCase';
import router from '@/router';
import { location } from '@/utils/router';
import { RouteName } from '@/enums';
import { useCommonStore } from '@/store/common';

export const handleError = async (error: any) => {
  const responseStatus = error?.response?.status;
  const responseData = error?.response?.data;

  if (responseStatus === 500) {
    await router.push(location({ name: RouteName.ERROR_500 }));

    throw error;
  }

  if (responseStatus === 403) {
    const authStatus = _kebabCase(responseData?.status);

    await router.push(location({
      name: RouteName.ERROR_403,
      params: { authStatus },
    }));

    throw error;
  }

  if (responseStatus !== 401) {
    throw error;
  }
  const commonStore = useCommonStore();

  const isNavigationSucceeded = await commonStore.oauth();

  if (isNavigationSucceeded) throw error;

  // если не получилось выполнить OAuth,
  // то выполняем обычное перенаправление на главную страницу IAM
  const iamUrl = commonStore.systemConfig?.IAM_URL;

  if (iamUrl) {
    window.location.replace(iamUrl);
  }

  throw error;
};
