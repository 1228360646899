import { RouteName } from '@/enums';
import { useCommonStore } from '@/store/common';

import type { NavigationGuardWithThis } from 'vue-router';

const isContinueRouteName = new Set([
  RouteName.ERROR_500,
]);

export const loadConfig: NavigationGuardWithThis<undefined> = async (to) => {
  if (isContinueRouteName.has(to.name as RouteName)) return true;

  const commonStore = useCommonStore();

  try {
    if (commonStore.isEmptyConfig) {
      await commonStore.loadSystemConfig();
    }

    return true;
  } catch (error) {
    return { name: RouteName.ERROR_500 };
  }
};
