import type { AxiosInstance } from 'axios';
import type {
  ThemeResponseItem,
  ThemeTheoryResponseItem,
  ThemeVideoResponseItem,
} from '@/types';

export default (axios: AxiosInstance) => ({
  async getItem(id: number) {
    try {
      const response = await axios.get<ThemeResponseItem>(`/v1/content-platform/get-theme-info/${id}`);
      return { response };
    } catch (error) {
      return { error };
    }
  },
  async getTheory(id: number) {
    try {
      const response = await axios.get<ThemeTheoryResponseItem>(`/v1/content-platform/get-theme-info/${id}/theory`);
      return { response };
    } catch (error) {
      return { error };
    }
  },
  async getVideo(id: number) {
    try {
      const response = await axios.get<ThemeVideoResponseItem>(`/v1/content-platform/get-theme-info/${id}/video`);
      return { response };
    } catch (error) {
      return { error };
    }
  },
});
