import { Mutations } from 'vuex-smart-module';
import type { LessonsState } from './state';
import type { SetItemPayload } from '../lessons/mutations.types';

export class LessonsMutations extends Mutations<LessonsState> {
  setItem({ item, value }: SetItemPayload) {
    this.state[item] = value;
  }
  setIsMountedCurrentTestBodyComponent(status: boolean) {
    this.state.isMountedCurrentTestBodyComponent = status;
  } 
}
