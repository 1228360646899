<template>
  <m-transition>
    <div v-if="show">
      <slot/>
    </div>
  </m-transition>
</template>

<script>
export default {
  name: 'MExpansion',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
