import { Actions } from 'vuex-smart-module';
import { axios } from '@/services/api';
import type { ExamGetters } from './getters';
import type { ExamMutations } from './mutations';
import type { ExamState } from './state';

export class ExamActions extends Actions<
ExamState,
ExamGetters,
ExamMutations,
ExamActions
> {
  async loadTestList({ lessonId, subjectId, isExam }: {
    lessonId: number;
    subjectId: number;
    isExam?: boolean;
  }) {
    const { data } = await axios.get(`exam/student/lesson/${lessonId}/exams/${subjectId}/tests`);
    this.commit('SET_TEST_LIST', { list: data, lessonId, isExam });
  }
  async sendStartExam({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    const { data } = await axios.post(`exam/student/lesson/${lessonId}/exams/${subjectId}/start`);

    this.commit('SET_EXAM_INFO', {
      examId: data.examSubjectId,
      data,
    });
  }
  async loadExamInfo({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    if (!this.state.examsInfo[subjectId]) {
      const { data } = await axios.get(`exam/student/lesson/${lessonId}/exams/${subjectId}/info`);
      this.commit('SET_EXAM_INFO', {
        examId: data.examSubjectId,
        data,
      });
    }
  }
  async loadExamResultsList({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    const { data: { items, examSubject, studentSpentTime } } = await axios.get(`exam/student/lesson/${lessonId}/exams/${subjectId}/results`);

    const testsList = items.map((test: any) => ({
      ...test,
      studentTestResult: {
        ...test.studentTestResult,
        showSolution: true,
      },
    }));

    this.commit('SET_TEST_LIST', { list: testsList, lessonId });
    this.commit('SET_EXAM_INFO', {
      examId: examSubject.id,
      data: { ...examSubject, studentSpentTime },
    });
    this.commit('SET_FINISHED_STATUS', examSubject.id);
  }
  async loadExamStats({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    const { data } = await axios.get(`exam/student/lesson/${lessonId}/exams/${subjectId}/results/total`);
    this.commit('SET_EXAM_STATS', data);
  }
  updateStudentAnswer({ id, value }: {
    id: number;
    value: any;
  }) {
    if (this.state.studentAnswerList[id]) {
      this.commit('UPDATE_TEST_ANSWER', { id, value });
    }
  }
  updateStudentAttaches({ id, value }: {
    id: number;
    value: any;
  }) {
    if (this.state.studentAnswerList[id]) {
      this.commit('UPDATE_TEST_ATTACHES', { id, value });
    }
  }
  updateCurrentTestAnswerLocal({ id, studentId, lessonId, toDelete }: {
    id: number;
    studentId: number;
    lessonId: number;
    toDelete: boolean;
  }) {
    this.commit('UPDATE_CURRENT_TEST_ANSWER_LOCAL', { id, studentId, lessonId, toDelete });
  }
  setFinishedStatus(subjectId: number) {
    this.commit('SET_FINISHED_STATUS', subjectId);
  }
  endExam({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    return axios.post(`exam/student/lesson/${lessonId}/exams/${subjectId}/finish`);
  }
  async sendTestAnswer({ subjectId, lessonId, testId }: {
    lessonId: number;
    subjectId: number;
    testId: number;
  }) {
    const isFinishedStatus = this.state.examsInfo[subjectId].isFinished;
    if (isFinishedStatus) return;
    const { answer, attaches } = this.state.studentAnswerList[testId];
    const preparedAnswer = answer.map((item: any) => item.value);

    const data = {
      lessonId,
      attaches,
      answer: preparedAnswer,
    };

    return axios.post(`/exam/student/lesson/${lessonId}/exams/${subjectId}/tests/${testId}`, data);
  }
}
