import { Module } from 'vuex-smart-module';
import { CorrectionsGetters } from './getters';
import { CorrectionsActions } from './actions';
import { CorrectionsMutations } from './mutations';
import { CorrectionsState } from './state';

export default new Module({
  state: CorrectionsState,
  mutations: CorrectionsMutations,
  actions: CorrectionsActions,
  getters: CorrectionsGetters,
});
