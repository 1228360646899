import type { IDebts } from './state.types';

export class DebtsState {
  debts: IDebts | undefined;

  /**
   * TODO:
   * Костыль, но ничего лучше не придумал.
   */
  isBackToDebtsPageByFlag = false;
}
