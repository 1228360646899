import { Mutations } from 'vuex-smart-module';
import type { DebtsState } from './state';
import type { IDebts } from './state.types';

export class DebtsMutations extends Mutations<DebtsState> {
  setDebts(debts: IDebts) {
    this.state.debts = debts;
  }
  toggleStatusBackToDebtsPage(status?: boolean) {
    this.state.isBackToDebtsPageByFlag = status ?? !this.state.isBackToDebtsPageByFlag;
  }
}
