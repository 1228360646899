import LS from '../index';

export interface TestAnswer {
  value: string;
}

export default class LSTests {
  private static prefixKey = '/test/';
  private static regExp = RegExp(`^${LSTests.prefixKey}`);
  private static ttlAdding = 1000 * 60 * 60 * 24 * 2; // сохранить на 2 дня

  public static set(testId: number, value: TestAnswer[]): void {
    LS.set<TestAnswer[]>(`${LSTests.prefixKey}${testId}`, value, LSTests.ttlAdding);
  }
  public static get(testId: number): TestAnswer[] | undefined {
    return LS.get<TestAnswer[]>(`${LSTests.prefixKey}${testId}`)?.value;
  }
  public static remove(testId: number): void {
    LS.remove(`${LSTests.prefixKey}${testId}`);
  }
  public static clear() {
    LS.clearByTTL(LSTests.regExp);
  }
}
