
import { defineComponent } from 'vue';
import colors from '@/assets/scss/modules/colors.modules.scss';

import type { PropType } from 'vue';
import type { VButtonMode, VButtonPredefinedColors } from '@/types';

export default defineComponent({
  props: {
    color: {
      type: String as PropType<keyof VButtonPredefinedColors>,
      default: 'orange',
    },
    text: {
      type: String,
      default: '',
    },
    expand: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<VButtonMode>,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const predefinedColors: VButtonPredefinedColors = {
      orange: colors.orange400,
      skyBlue: colors.skyBlue500,
      green: colors.green600,
      gray: colors.blueGray300,
      darkGray: colors.blueGray500,
      blue: colors.blue500,
    };

    return {
      predefinedColors,
    };
  },
  computed: {
    colorCode() {
      return this.predefinedColors[this.color];
    },
    styles() {
      const isSecondary = this.mode === 'secondary';
      const backgroundColor = isSecondary ? 'white' : this.colorCode;
      const color = isSecondary ? this.colorCode : 'white';
      const borderColor = this.colorCode;

      return {
        backgroundColor,
        color,
        borderColor,
      };
    },
    classes() {
      return {
        extended: this.expand,
      };
    },
  },
});
