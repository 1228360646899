
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: [Boolean, Array],
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      default: 'option',
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: 'option',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
      validator: (value: string) => ['success', 'error', 'marked'].includes(value),
    },
    dataTestPostfix: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: null,
    },
    /**
     * Добавлена дабы сохранить обратную совместимость
     */
    labelColor: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    checked() {
      return typeof (this.modelValue) === 'boolean' ? this.modelValue : this.modelValue.includes(this.value);
    },
    classes() {
      if (!this.status) return '';
      return `m-checkbox_status_${this.status}`;
    },
    checkboxBoxClasses() {
      return this.checked && this.color
        ? `m-checkbox__box_${this.color}`
        : '';
    },
    checkboxTestClasses() {
      return this.checked && this.labelColor
        ? `m-checkbox__text_${this.labelColor}`
        : '';
    },
  },
  methods: {
    onChange(event: Event) {
      const target = event.target as HTMLInputElement;

      let result = target.checked as Boolean | any[];

      // Если v-model - массив
      if (typeof (this.modelValue) === 'object') {
        result = Array.from(this.modelValue);
        if (target.checked) {
          result.push(this.value);
        } else {
          const index = result.indexOf(this.value);
          if (index !== -1) {
            result.splice(index, 1);
          }
        }
      }

      this.$emit('update:modelValue', result);
    },
  },
});
