<template>
  <router-link
    v-if="isRouterLink"
    :to="route || '#'"
    class="m-plank"
    :class="{ 'm-plank_locked': isLocked }"
  >
    <slot/>
  </router-link>
  <a
    v-else
    :class="{ 'm-plank_locked': isLocked }"
    href="#"
    class="m-plank"
    @click.prevent="$emit('click')"
  >
    <slot/>
  </a>
</template>

<script>
export default {
  name: 'MPlank',
  props: {
    isRouterLink: {
      type: Boolean,
      default: false,
    },
    route: {
      type: Object,
      default: null,
    },
    currentLink: {
      type: String,
      default: '#',
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  .m-plank {
    border: 1px solid $m-gray;
    border-radius: 6px;
    box-shadow: 2px 2px 0 0 rgba(168, 161, 154, .06);
    min-height: 66px;
    padding: 12px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 15px;
    font-weight: bold;
    transition: all .3s ease;
    position: relative;
    overflow: hidden;
    word-break: break-word;
    &:hover {
      border-color: $color-main;
      box-shadow: 2px 2px 0 0 rgba(229, 134, 93, .06);
    }
    &_locked {
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: .7;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
</style>
