import bugReport from './bug-report';
import classSubjects from './class-subjects';
import sections from './sections';
import themes from './themes';
import tests from '@/services/api/v1/tests';
import type { AxiosInstance } from 'axios';
import type {
  OAuth,
  Logout,
  SystemConfig,
  Profile,
} from '@/types';
import type { LocationQuery } from 'vue-router';

export default (axios: AxiosInstance) => ({
  loadSystemConfig() {
    return axios.get<SystemConfig>('/v1/system/system-config');
  },
  loadProfile() {
    return axios.get<Profile>('/v1/profile/auth/me');
  },
  logout() {
    return axios.get<Logout>('/v1/auth/logout-link');
  },
  oauth({ fromUrl }: { fromUrl: string }) {
    return axios.get<OAuth>('/v1/auth/redirect-to-oauth-provider', {
      params: {
        from_url: fromUrl,
      },
    });
  },
  authorize(payload: LocationQuery) {
    return axios.post('/v1/auth/oauth-result', payload);
  },
  classSubjects: classSubjects(axios),
  sections: sections(axios),
  themes: themes(axios),
  bugReport: bugReport(axios),
  tests: tests(axios),
});
