<template>
  <Teleport
    :disabled="!value"
    to="#mx-modal"
  >
    <div class="mx-modal">
      <div
        class="mx-modal__wrap"
        @click="onWrapClick"
      />
      <div
        :class="withoutPaddings ? 'mx-modal__container_without-paddings' : ''"
        class="mx-modal__container"
      >
        <m-button
          v-if="canClose"
          view="icon"
          size="content"
          class="mx-modal__close-btn"
          @click="$emit('input', false)"
        >
          <SvgIcon name="@icons/LMSIcons/close.svg"/>
        </m-button>
        <div class="mx-modal__container-inner">
          <slot/>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'MxModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
    withoutPaddings: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onWrapClick() {
      if (this.canClose) {
        this.$emit('input', false);
      }
    },
  },
};
</script>

<style lang="scss">
.mx-modal {
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 20px 0;
  overflow-y: auto;
  text-align: center;

  &:before {
    content:'';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &__wrap {
    background: rgba(255, 255, 255, .9);
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  &__container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    min-height: 200px;
    min-width: 250px;
    padding: 40px 20px 12px;
    z-index: 11;

    max-width: 100%;
    overflow-x: auto;

    &_without-paddings {
      padding: 40px 0 12px;
    }
  }

  &__container-inner {
    position: relative;
    width: fit-content;
  }

  &__close-btn {
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
  }
}
</style>
