import type { AxiosInstance } from 'axios';
import type {
  ClassSubjectResponseItem,
  ClassSubjectsResponseList,
} from '@/types';

export default (axios: AxiosInstance) => ({
  async getList() {
    try {
      const response = await axios.get<ClassSubjectsResponseList>('/v1/content-platform/class-subjects');
      return { response };
    } catch (error) {
      return { error };
    }
  },
  async getItem(id: number) {
    try {
      const response = await axios.get<ClassSubjectResponseItem>(`/v1/content-platform/get-class-subject-info/${id}`);
      return { response };
    } catch (error) {
      return { error };
    }
  },
});
