// import creator from '../../../lib/creator';
import { defineComponent, h } from 'vue';
import './Input.scss';

// const inputBase = creator();

export default defineComponent({
  name: 'mx-input',
  props: {
    name: String,
    label: String,
    placeholder: String,
    required: Boolean,
    id: String,
    disabled: Boolean,
    readonly: Boolean,
    value: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'on',
      validator: v => ['on', 'nope'].includes(v),
    },
    type: {
      type: String,
      default: 'text',
      validator(v) {
        return [
          'url',
          'text',
          'password',
          'tel',
          'search',
          'number',
          'email',
        ].includes(v);
      },
    },
  },
  computed: {
    innerId() {
      // this._uid - уникальный id компонента во vue
      return this.id || `input-${this._uid}`;
    },
    hasLabel() {
      return !!(this.label || this.$slots.label);
    },
    computedClasses() {
      return {
        'mx-input_required': this.required,
        'mx-input_disabled': this.disabled,
      };
    },
  },
  methods: {
    renderLabel() {
      if (!this.hasLabel) {
        return null;
      }

      return h('label', {
        class: ['mx-input__label'],
        for: this.innerId,
      }, this.$slots.label || this.label);
    },
    renderInput() {
      return h('input', {
        ref: 'input',
        value: this.value,
        // attrs: {
        //   id: this.innerId,
        //   name: this.name,
        //   type: this.type,
        //   placeholder: this.placeholder,
        //   required: this.required,
        //   disabled: this.disabled,
        //   autocomplete: this.autocomplete,
        //   readonly: this.readonly,
        // },
        ...this.$attrs,

        // on: {
        //   ...this.$listeners,
        // },

        id: this.innerId,
        name: this.name,
        type: this.type,
        placeholder: this.placeholder,
        required: this.required,
        disabled: this.disabled,
        autocomplete: this.autocomplete,
        readonly: this.readonly,
        staticClass: ['mx-input__input'],
      });
    },
    renderController() {
      return this.renderInput;
    },
    renderContent() {
      return h('div', {
        class: ['mx-input', this.computedClasses ],
      }, [
        this.renderLabel(),
        this.renderInput(),
      ]);
    },
  },
  render() {
    return this.renderContent;
  },
});
