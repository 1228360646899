import { RouteName } from '@/enums';
import { useCommonStore } from '@/store/common';

import type { NavigationGuardWithThis } from 'vue-router';

const isContinueRouteName = new Set([
  RouteName.ERROR_500,
  RouteName.ERROR_403,
  RouteName.ERROR_404,
  RouteName.OAUTH_RESULT,
]);

export const loadCommonData: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  if (isContinueRouteName.has(to.name as RouteName)) return next();

  const commonStore = useCommonStore();

  try {
    if (!commonStore.profile) {
      await commonStore.loadProfile();
    }

    return next();
  } catch {
    return next({ name: RouteName.ERROR_403 });
  }
};
