export const enum TypeLog {
  SET_BEGIN = 'SET (begin)',
  SET_BREACK = 'SET (break)',
  SET_END = 'SET (end)',
  GET_BEGIN = 'GET (begin)',
  GET_BREACK = 'GET (break)',
  GET_END = 'GET (end)',
  REMOVE_BEGIN = 'REMOVE (begin)',
  REMOVE_BREACK = 'REMOVE (break)',
  REMOVE_END = 'REMOVE (end)',
  CLEAR_BEGIN = 'CLEAR (begin)',
  CLEAR_BREACK = 'CLEAR (break)',
  CLEAR_END = 'CLEAR (end)',
}
