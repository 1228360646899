<template>
  <div
    :style="{ position: position }"
    :class="{ 'm-preloader_opaque': opaque }"
    class="m-preloader"
  >
    <loading-spinner
      :animation-duration="1500"
      :size="Number(size)"
    />
    <m-button
      v-if="cancelable"
      :style="{ position: 'absolute' }"
      view="flat"
      size="small"
      :disabled="disabled"
      @click="onCancel"
      v-text="'Отмена'"
    />
  </div>
</template>

<script>
import LoadingSpinner from '@/uikit/maxify/LoadingSpinner';

export default {
  name: 'MPreloader',
  components: { LoadingSpinner },
  props: {
    position: {
      type: String,
      default: 'fixed',
    },
    opaque: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 80,
    },
    cancelable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCancel(event) {
      this.$emit('cancel', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.m-preloader {
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  justify-content: center;
  align-items: center;

  &_opaque {
    background-color: #fff;
  }
}
</style>
