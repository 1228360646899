<template>
  <span
    class="label"
    :class="$attrs"
  >
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'MLabel',
  inheritAttrs: false,

};
</script>

<style lang="scss" scoped>
  .label {
    color: $m-text;
    display: inline-block;

    &_required::after {
      content: '*';
      display: inline-block;
      color: $m-light-red;
    }
  }
  .success {
    padding: 4px 8px;
    border-radius: 100px;
    color: $m-white;
    background-color: $m-green;
  }
</style>
