<template>
  <div
    :class="`m-star-rating_position_${position}`"
    class="m-star-rating"
  >
    <div class="m-star-rating__wrap">
      <template v-for="star in starsReversed" :key="star">
        <input
          :id="`${id}-${star}`"
          :name="`${id}-star`"
          :value="star"
          :disabled="disabled"
          :checked="star === value"
          type="radio"
          class="m-star-rating__input"
          :class="{ 'm-star-rating__input_active': star === value }"
          @change="onChange(star)"
        >
        <label
          :for="`${id}-${star}`"
          :class="labelClasses"
          class="m-star-rating__label"
        >
          <SvgIcon
            data="@icons/LMSIcons/star.svg"
            class="m-star-rating__star"
          />
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MStarRating',
  model: {
    event: 'change',
  },
  props: {
    starsCount: {
      type: Number,
      default: 5,
    },
    id: {
      type: String,
      default: 'star',
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showcase: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    starsReversed() {
      const result = [];

      for (let i = this.starsCount; i > 0; i--) {
        result.push(i);
      }

      return result;
    },
    labelClasses() {
      let result = '';

      result += this.disabled ? 'm-star-rating__label_disabled' : '';
      result += this.showcase ? 'm-star-rating__label__showcase' : '';

      return result;
    },
  },
  methods: {
    onChange(star) {
      if (this.value !== star) {
        this.$emit('change', star);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.m-star-rating {
  display: flex;
  &_position {
    &_left {
      justify-content: flex-start;
    }
    &_center {
      justify-content: center;
    }
    &_right {
      justify-content: flex-end;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  &__input {
    display: none;
    &:checked + label {
      pointer-events: none;
    }

    &_active ~ label svg,
    &:checked ~ label svg,
    &:not(:checked) + label:hover svg,
    &:not(:checked) + label:hover ~ label svg {
      fill: $color-main;
    }
  }
  &__label {
    cursor: pointer;
    svg {
      fill: $m-text-light;
    }
    &__showcase {
      pointer-events: none;
      &:hover {
        svg {
          fill: transparent;
        }
      }
    }
    &_disabled {
      pointer-events: none;
      svg {
        fill: #ddd !important;
      }
    }
  }
  &__star {
    transition: fill .2s ease;
  }
}
</style>
