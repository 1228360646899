import cloneDeep from 'lodash/cloneDeep';

import {
  testResults,
  answerValueAsId,
  checkboxOrRadio,
} from '@/components/lessonPage/tests/common/LessonTestTypes/testStates.js';

export const getResultFromRightAnswers = ({ educationTestRightAnswers, type, studentTestResult }: any) => {
  const answer = educationTestRightAnswers.map((item: any) => {
    const value = answerValueAsId({ type })
      ? String(item.id)
      : item.variants[0];
    return { value, result: true };
  });

  return {
    ...(
      studentTestResult
      || { showSolution: true, result: testResults.false } // Для отображения хода решения в модуле препода
    ),
    answer,
  };
};

export const getAnswerListItem = (studentTestResult: any) => {
  // answer: массив ответов в ручную: радио, чекбоксы, тексты и тд
  // attaches: массив прикрепленных файлов
  // disabled: статус кнопки отправить см: getTestDisableStatus
  const answerItem: any = { answer: [], attaches: [], disabled: true };

  if (studentTestResult) {
    if (studentTestResult?.answer?.length) {
      answerItem.answer = cloneDeep(studentTestResult.answer);
      answerItem.oldAnswer = cloneDeep(studentTestResult.answer);
    }

    if (studentTestResult?.attaches?.length) {
      answerItem.attaches = cloneDeep(studentTestResult.attaches);
      answerItem.oldAttaches = cloneDeep(studentTestResult.attaches);
    }
  }

  return answerItem;
};

export const mapTestToAnswerList = (list: any) => list.reduce((resObj: any, { id, educationTestRightAnswers, studentTestResult, type }: any) => {
  resObj[id] = educationTestRightAnswers?.length && !checkboxOrRadio({ type })
    ? getResultFromRightAnswers({ educationTestRightAnswers, type })
    : getAnswerListItem(studentTestResult);

  return resObj;
}, {});
