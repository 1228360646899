export type RCRequestTitle = string;
type RCAdd = (requestTitle: RCRequestTitle, abortController: AbortController) => void;
type RCRemove = (requestTitle: RCRequestTitle) => void;
type RCAbort = (requestTitle: RCRequestTitle) => void;
type RCContains = (requestTitle: RCRequestTitle) => boolean;

type RCActiveRequestConfig = {
  abortController: AbortController;
}

type RCActiveRequestConfigCollection = RCActiveRequestConfig[];

type RCActiveRequests = Record<string, RCActiveRequestConfigCollection>;

type Index = {
  _activeRequestsConfigs: RCActiveRequests;
  add: RCAdd;
  remove: RCRemove;
  abort: RCAbort;
  contains: RCContains;
}

export const requestsController: Index = {
  _activeRequestsConfigs: {},
  add(requestTitle: RCRequestTitle, abortController: AbortController) {
    const config = {
      abortController,
    };
    if (!this.contains(requestTitle)) {
      this._activeRequestsConfigs[requestTitle] = [config];
      return;
    }
    this._activeRequestsConfigs[requestTitle].push(config);
  },
  remove(requestTitle: RCRequestTitle) {
    delete this._activeRequestsConfigs[requestTitle];
  },
  abort(requestTitle: RCRequestTitle) {
    if (this.contains(requestTitle)) {
      this._activeRequestsConfigs[requestTitle].forEach((el) => {
        el.abortController.abort();
      });
      this.remove(requestTitle);
      return;
    }
    throw new Error('Указанный запрос отсутствует в контролере');
  },
  contains(requestTitle: RCRequestTitle) {
    return requestTitle in this._activeRequestsConfigs;
  },
};
