import './MTextarea.scss';
import MInput from '../MInput/index';
import { defineComponent, h } from 'vue';

export default defineComponent({
  extends: MInput,
  name: 'm-textarea',
  props: {
    rows: {
      type: [Number, String],
      default: '10',
      validator: v => !Number.isNaN(parseFloat(v)),
    },
    rowHeight: {
      type: [Number, String],
      default: 12,
      validator: v => !Number.isNaN(parseFloat(v)),
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: Boolean,
      default: true,
    },
    state: {
      type: String,
      default: null,
      validator(value) {
        return ['success', 'error'].includes(value);
      },
    },
  },
  computed: {
    computedClasses() {
      return {
        [`mx-input_state_${this.state}`]: this.state,
        'mx-input_resizable': this.resizable,
        'mx-input_autosize': this.autosize,
        // TODO ок замена или нет?
        ...MInput.computed.computedClasses.call(this),
        // ...MInput.options.computed.computedClasses.call(this),
      };
    },
  },
  mounted() {
    if (this.autosize) {
      this.calculateAutosize();
    }
  },
  methods: {
    calculateAutosize() {
      const textarea = this.$refs.input;
      if (!textarea) return;

      textarea.style.height = '0';
      const height = textarea.scrollHeight;
      const minHeight = Number(this.rows) * Number(this.rowHeight);
      textarea.style.height = `${Math.max(minHeight, height)}px`;
    },
    handleInput(e) {
      MInput.options.methods.handleInput.call(this, e);
      if (this.autosize) {
        this.calculateAutosize();
      }
    },
    renderInput() {
      // TODO ок замена или нет?
      // const textarea = MInput.options.methods.renderInput.call(this);

      // const textarea = MInput.methods.renderInput.call(this);
      // textarea.tag = 'textarea';
      // textarea.data.attrs.rows = this.rows;
      // textarea.data.attrs['data-test-id'] = 'textAreaInput';
      // textarea.data.staticClass = 'mx-input__textarea custom-scroll-v1';
      // delete textarea.data.attrs.type;
      // return textarea;

      return h('textarea', {
        tag: 'textarea',
        rows: this.rows,
        class: [ 'mx-input__textarea custom-scroll-v1'],
      });
    },
  },
});
