import { Mutations } from 'vuex-smart-module';
import type { RootState } from './state';

type SetItemPayload = {
  item: keyof RootState;
  value: any;
}

export class RootMutations extends Mutations<RootState> {
  setItem({ item, value }: SetItemPayload) {
    this.state[item] = value;
  }
  SET_PERMISSIONS(payload: any) {
    this.state.permissions = payload;
  }
}
