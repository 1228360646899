
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MRow',
  inheritAttrs: false,
  props: {
    componentIs: {
      type: String,
      default: 'div'
    },
    adaptive: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: String,
      default: 'wrap',
      validator(value: string) {
        return ['wrap', 'wrap-reverse', 'nowrap'].includes(value);
      },
    }
  },
  computed: {
    classes() {
      return {
        'm-row': !this.adaptive,
        'm-row_adaptive': this.adaptive,
      };
    },
    styles() {
      return {
        'flex-wrap': this.wrap,
      };
    }
  }
})
