import { AuthStatus, RouteParam } from '@/enums';
import type { NavigationGuardWithThis } from 'vue-router';

export const validateAuthStatusParam: NavigationGuardWithThis<undefined> = (to) => {
  const authStatus = to.params[RouteParam.AUTH_STATUS];

  if (!authStatus) {
    return true;
  }

  const authStatusList = Object.values(AuthStatus);

  if (!Array.isArray(authStatus) && authStatusList.includes(authStatus as AuthStatus)) {
    return true;
  }

  if (to.name) {
    return {
      name: to.name,
    };
  }

  return false;
};
