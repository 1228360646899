<template>
  <div class="m-audio">
    <audio
      class="m-audio-player"
      controls
      :src="url"
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.m-audio-player {
  outline: none;
}
</style>
