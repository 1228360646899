<template>
  <div class="statistic-scale">
    <transition name="fade-anim">
      <div
        v-if="hover"
        class="statistic-scale__hint"
      >
        <div class="hint">
          <h4
            class="hint__title"
            v-text="hintTitle"
          />
          <ul class="hint__list">
            <li
              v-for="(item, index) in statusItems"
              :key="index"
              class="hint__item"
            >
              <div
                :class="{ status_percents: type === 'percent' }"
                class="status"
              >
                <div
                  :class="`status__color_status_${item.color}`"
                  class="status__color"
                />
                <span class="status__count">
                  {{ type === 'number'
                    ? item.count
                    : `${item.count}%` }}
                </span>
                <!-- TODO: удалить $t <p
                  class="status__text"
                  v-text="$t(item.title)"
                /> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <div
      class="statistic-scale__progress"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="progress__count">{{ progressPercent }}%</div>
      <ul class="progress__line">
        <li
          v-for="(item, index) in filteredItems"
          :key="index"
          :style="{ width: `${item.percent}%` }"
          :class="`progress__item_status_${item.color}`"
          class="progress__item"
        />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MStatisticScale',
  props: {
    statusItems: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    /**
     *  Тип отображаемых данных.
     * number | percent
    */
    type: {
      type: String,
      default: 'number',
    },
    all: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    calculatedAll() {
      let totalCount = 0;

      this.statusItems.forEach((item) => {
        totalCount += item.count;
      });

      return totalCount;
    },
    filteredItems() {
      return this.statusItems.map((item) => {
        return {
          ...item,
          percent: (item.count * 100) / this.all,
        };
      });
    },
    /**
     * Считает конечный процент выполненных (даже неудачно) заданий.
    */
    progressPercent() {
      let percent = 0;

      const divider = this.type === 'percent'
        ? 100
        : this.all;

      this.statusItems.forEach((item) => {
        if (item.color !== 'white') {
          percent += item.count;
        }
      });

      return Math.round((100 * percent) / divider);
    },
    /**
     * Заголовок всплывающей подсказки.
     * @returns {string} - Текст заголовка.
    */
    hintTitle() {
      return `${this.title} (${this.all})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistic-scale {
  position: relative;

  &__hint {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid $m-light-gray;
    bottom: calc(100% + 12px);
    box-shadow: 6px 4px 4px rgba(0, 0, 0, .1);
    left: -50%;
    min-width: 200px;
    padding: 10px 8px;
    position: absolute;
    z-index: 9999;

    &::after {
      background-color: #fff;
      border-bottom: 1px solid $m-light-gray;
      border-left: 1px solid $m-light-gray;
      border-radius: 2px;
      bottom: -7px;
      box-shadow: -2px 5px 4px rgba(0, 0, 0, .1);
      content: '';
      display: block;
      height: 12px;
      left: 50%;
      margin-left: -6px;
      position: absolute;
      transform: rotate(-45deg);
      width: 12px;
    }
  }

  &__progress {
    align-items: flex-end;
    display: flex;
    width: 100%;
  }
}

.hint {
  &__title {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 8px;
  }

  &__item {
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.status {
  display: grid;
  grid-column-gap: 6px;
  grid-template-columns: 18px 22px 1fr;

  &_percents {
    grid-template-columns: 18px 28px 1fr;
  }

  &__color {
    border: 1px solid $m-text-light;
    height: 16px;
    width: 18px;
  }

  &__count {
    color: #000;
    font-size: 13px;
    line-height: 18px;
    text-align: right;
  }

  &__text {
    color: $m-text-light;
    font-size: 13px;
    line-height: 18px;
  }
}

.progress {
  &__count {
    color: $m-text-light;
    font-size: 13px;
    line-height: 15px;
    margin-right: 4px;
    text-align: right;
    width: 33px;
  }

  &__line {
    border: 1px solid $m-text-light;
    display: flex;
    flex-grow: 1;
    height: 16px;
  }

  &__item {
    height: 100%;
    width: 0;
  }
}

.status__color_status,
.progress__item_status {
  &_green {
    background-color: $m-light-green;
  }

  &_yellow {
    background-color: $m-light-yellow;
  }

  &_red {
    background-color: $m-light-red;
  }

  &_orange {
    background-color: $color-main;
  }

  &_white {
    background-color: #fff;
  }
}
</style>
