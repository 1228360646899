import { TypeLog } from './enums';

export interface LSData<T> {
  ttl: number;
  value: T;
}

export default class LS {
  private static store = localStorage;
  private static debug = false;
  private static ttlAdding = 1000 * 60 * 60 * 24 * 10; // сохранить на 10 дня

  public static set<T>(key: string, value: T, ttlAdding = LS.ttlAdding): void {
    LS.log(TypeLog.SET_BEGIN, key, value);

    if (!LS.store || !value) {
      LS.log(TypeLog.SET_BREACK);
      return;
    }

    const data: LSData<T> = {
      ttl: Date.now() + ttlAdding,
      value,
    };

    const jsonResponse = JSON.stringify(data);
    LS.store.setItem(key, jsonResponse);

    LS.log(TypeLog.SET_END, jsonResponse);
  }
  public static get<T>(key: string): LSData<T> | undefined {
    LS.log(TypeLog.GET_BEGIN, key);

    if (!LS.store) {
      LS.log(TypeLog.GET_BREACK);
      return;
    }

    const data = LS.store.getItem(key);

    if (!data) {
      LS.log(TypeLog.GET_BREACK, key);
      return;
    }

    const parsedObj: LSData<T> = JSON.parse(data);

    LS.log(TypeLog.GET_END, parsedObj);
    return parsedObj;
  }
  public static remove(key: string): void {
    LS.log(TypeLog.REMOVE_BEGIN, key);

    if (!LS.store) {
      LS.log(TypeLog.REMOVE_BREACK);
      return;
    }

    LS.store.removeItem(key);

    LS.log(TypeLog.REMOVE_END, key);
  }
  public static clear() {
    LS.log(TypeLog.CLEAR_BEGIN);

    if (!LS.store) {
      LS.log(TypeLog.CLEAR_BREACK);
      return;
    }

    LS.store.clear();
    LS.log(TypeLog.CLEAR_END);
  }
  public static clearByTTL<T>(filterRegExp: RegExp) {
    const filteredKeys = LS.keys.filter((key) => filterRegExp.test(key));

    filteredKeys.forEach((key) => {
      LS.log(TypeLog.CLEAR_BEGIN, key);

      const data = LS.get<T>(key);

      if (!data || !data.ttl || Date.now() <= data.ttl) {
        LS.log(TypeLog.CLEAR_BREACK, key);
        return;
      }

      LS.remove(key);
      LS.log(TypeLog.CLEAR_END, key);
    });
  }

  public static get keys(): string[] {
    return Object.keys(LS.store);
  }

  /* Debug */

  private static logSyle = `
    background: #3C86DE;
    border-radius: 0.5em;
    color: white;
    font-weight: bold;
    padding: 2px 0.5em;
  `;

  public static toggleDebug(status?: boolean) {
    LS.debug = status ?? !LS.debug;
  }

  private static log(typeLog: TypeLog, ...args: any) {
    if (!LS.debug) return;

    console.log('%cLS', LS.logSyle, `${typeLog}:`, ...args);
  }
}
