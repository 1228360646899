<template>
  <div
    :class="selectClasses"
    class="m-select"
  >
    <m-label
      v-if="label"
      class="m-select__label font-400-15-17"
    >
      {{ label }}
    </m-label>
    <div class="m-select__wrapper">
      <div
        v-click-outside="closeOptions"
        :class="activatorClasses"
        class="m-select__activator"
        @keydown.enter="closeOptions"
        @click.prevent="onActivatorClick"
      >
        <template v-if="!(withSearch || withManualEnter)">
          <slot
            name="selected"
            v-bind="{ ...$props, textValue }"
          >
            <span
              class="m-select__text"
              :class="{ 'm-select__text_placeholder': !value }"
            >
              {{ textValue || placeholder }}
            </span>
          </slot>
        </template>
        <input
          v-else
          ref="searchInput"
          v-model="search"
          :placeholder="placeholder"
          type="text"
          class="m-select__input"
        >
        <SvgIcon
          data="@icons/LMSIcons/arrow_bottom.svg"
          class="m-select__arrow"
          data-test-id="mSelectArrow"
        />
      </div>
      <div class="m-select__selected">
        <div
          v-for="item in selected"
          :key="item[itemValue]"
          class="m-select__selected-item"
        >
          <span v-text="item[itemText]"/>
          <SvgIcon
            data="@icons/LMSIcons/close.svg"
            class="m-select__selected-delete"
            @click="deleteFromSelected(item[itemValue])"
          />
        </div>
      </div>
    </div>
    <transition name="slide-top-anim">
      <m-popup
        v-if="optionsIsVisible && !selectionLimitExceeded"
        :has-header="false"
        :has-footer="false"
        :has-arrow="false"
        class="m-select__m-popup"
      >
        <m-list
          v-if="filteredOptions.length"
          :items="filteredOptions"
          class="m-select__options"
        >
          <template v-slot:default="{ item, index }">
            <div
              :class="{
                active: index === currentIndex,
                disabled: item.disabled,
                selected: selected.some(item => item[itemValue] === item[itemValue])
              }"
              class="m-select__option"
              :data-test-id="`mSelectOption${index}`"
              @click="onOptionClick(item[itemValue] !== undefined ? item[itemValue] : item.id)"
            >
              <slot
                name="options"
                :item="item"
              >
                {{ item[itemText] }}
              </slot>
              <SvgIcon
                v-if="iconState"
                :data="`@icons/LMSIcons/${item.value}.svg`"
                :class="iconColorClass(item.value)"
                class="m-select__status-icon"
              />
            </div>
          </template>
        </m-list>
        <span
          v-else
          class="m-select__no-data"
        >
          {{ search.length ? 'Совпадений не найдено' : 'Нет данных' }}
        </span>
      </m-popup>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MSelect',
  props: {
    /**
     * Значение возвращаемое после выбора элемента может быть (Object, String, Number)
     */
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    /**
     * Лейбл, как у инпутов. Может быть, может не быть
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * true, если поле обязательное
     */
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * true, если селект с поиском
     */
    withSearch: {
      type: Boolean,
      default: false,
    },
    /**
     * true, если селект с ручным вводом
    */
    withManualEnter: {
      type: Boolean,
      default: false,
    },
    /**
     * Определяет placeholder для селекта
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Элементы для выбора
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * Название пропса в элементах options, значение которого видит юзер
     */
    itemText: {
      type: String,
      default: 'text',
    },
    /**
     * Название пропса в элементах options, значение которого возвращается в v-model
     */
    itemValue: {
      type: String,
      default: null,
    },
    /**
     * Подсветка для background, принимает - (success || error || neutral)
     */
    state: {
      type: String,
      default: null,
    },
    /**
     * Активирует статусные иконки, принимает - (true || false)
     */
    iconState: {
      type: Boolean,
      default: false,
    },
    /**
     * Активатор выделяется жирным, принимает - (true || false)
     */
    boldActivator: {
      type: Boolean,
      default: false,
    },
    /**
     * Возвращать не объект, а только значение value - (true || false)
     */
    returnValue: {
      type: Boolean,
      default: false,
    },
    multipleChoise: {
      type: Boolean,
      default: false,
    },
    selectionLimit: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      optionsIsVisible: false,
      search: '',
      selected: [],
    };
  },
  computed: {
    selectClasses() {
      return {
        'm-select_required': this.required,
        'm-select_disabled': this.disabled,
      };
    },
    activatorClasses() {
      return {
        'm-select__activator_focus': this.optionsIsVisible,
        [`m-select__activator_state_${this.state}`]: this.state,
        ' m-select__activator_bold': this.boldActivator,
      };
    },
    textValue() {
      if (this.value === null) {
        return null;
      }

      return this.currentIndex !== -1 ? this.options[this.currentIndex][this.itemText] : '';
    },
    preparedOptions() {
      return this.options.map((option, index) => {
        return {
          ...option,
          index,
        };
      });
    },
    filteredOptions() {
      const enabledOptions = this.preparedOptions.filter((option) => {
        return !option.disabledOption;
      });

      if (this.withSearch && this.search.length) {
        return enabledOptions.filter((option) => {
          return option[this.itemText].toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return enabledOptions;
    },
    currentIndex() {
      if (this.value !== null) {
        if (this.itemValue === null) {
          return this.options.findIndex(option => (option.id || option) === (this.value.id || this.value));
        }
        return this.options.findIndex(option => option[this.itemValue] === this.value);
      }

      return -1;
    },
    selectedValues() {
      return this.selected.map(item => item[this.itemValue]);
    },
    selectionLimitExceeded() {
      if (!+this.selectionLimit) return false;
      return this.selected.length >= +this.selectionLimit;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val !== null && (this.withSearch || this.withManualEnter) && !this.multipleChoise) {
          this.search = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeOptions(e) {
      if (this.withManualEnter && this.optionsIsVisible) {
        const key = this.itemValue || this.itemText;
        const emittedObj = {
          [key]: this.search,
        };
        this.$emit('input', emittedObj);
      }
      if (!this.multipleChoise || !e.target.classList.contains('m-select__option')) {
        this.optionsIsVisible = false;
      }
    },
    onOptionClick(clickedValue) {
      if (this.returnValue) {
        this.$emit('input', clickedValue);
        return;
      }

      const selected = this.options.find((option) => {
        return this.itemValue ? option[this.itemValue] === clickedValue : option.id === clickedValue;
      });

      if (!this.multipleChoise) {
        const emitted = this.itemValue ? selected[this.itemValue] : selected;
        this.optionsIsVisible = false;
        this.$emit('input', emitted);
      } else {
        const alreadySelected = this.selected.some(item => item[this.itemValue] === clickedValue);
        if (alreadySelected) {
          this.deleteFromSelected(clickedValue);
        } else {
          this.selected.push(selected);
        }
        this.$emit('input', this.selectedValues);
        this.search = '';
        this.$refs.searchInput.focus();
      }
    },
    onActivatorClick() {
      if (this.withSearch || this.withManualEnter) {
        this.optionsIsVisible = true;
      } else {
        this.optionsIsVisible = !this.optionsIsVisible;
      }
    },
    iconColorClass(value) {
      return `m-select__status-icon_${value}`;
    },
    deleteFromSelected(itemValue) {
      const idx = this.selected.findIndex(selectedItem => selectedItem[this.itemValue] === itemValue);
      this.selected.splice(idx, 1);
      this.$emit('input', this.selectedValues);
    },
  },
};
</script>

<style lang="scss" scoped>
.m-select {
  position: relative;

  &__label {
    margin-bottom: 8px;
  }

  &__wrapper {
    border: 1px solid $m-gray;
    border-radius: 6px;
  }

  &__activator {
    position: relative;
    height: 32px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      border-color: $color-lighten;
    }

    &_focus {
      border-color: $m-dark-gray;

      &:hover {
        border-color: $m-dark-gray;
      }
    }

    &_state {
      &_success {
        background-color: $m-very-light-green;
      }

      &_error {
        background-color: $m-very-light-red;
      }

      &_neutral {
        background-color: #ffe0d0;
      }
    }

    &_bold {
      .m-select__text {
        font-weight: 500;
      }
    }
  }

  &__selected {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;

      &:hover {
        background-color: $m-light-gray;
      }
    }

    &-delete {
      width: 10px;
      height: 10px;
      cursor: pointer;
      transition: fill 0.2s;
      margin-right: 3px;

      &:hover {
        fill: $color-main;
      }
    }
  }

  &__status-icon {
    position: absolute;
    right: 14px;

    &_mark {
      fill: $m-light-green;
    }

    &_close {
      fill: $m-light-red;
    }

    &_no-result {
      fill: $m-dark-gray;
    }
  }

  &__text {
    flex-grow: 1;
    width: 0;
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;

    &_placeholder {
      font-size: 13px;
      color: $m-text-light;
    }
  }

  &__m-popup {
    margin-top: 0;
    width: 100%;
    max-height: 190px;
    overflow: auto;
  }

  &__option {
    padding: 8px 15px;
    cursor: pointer;
    transition: all .3s ease;
    align-items: center;
    position: relative;

    &.active {
      color: $color-main;
    }

    &.disabled {
      pointer-events: none;
      color: $m-text-light;
    }

    &.selected {
      background-color: $m-light-gray;
    }

    &:hover {
      background-color: $m-light-gray;
    }

    &:focus {
      background-color: $m-gray;
    }
  }

  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 0 20px;
    cursor: pointer;
  }

  &__input {
    border: none;
    padding: 0;
    width: calc(100% - 20px);
    overflow-x: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  &__arrow {
    position: absolute;
    right: 12px;
    bottom: 9px;
    width: 12px;
    height: 12px;
  }

  &_required {
    .m-select__label {
      &::after {
        content: '*';
        display: inline-block;
        color: $m-light-red;
      }
    }
  }

  &_disabled {
    pointer-events: none;

    .m-select__wrapper {
      background: $m-light-gray;
    }

    .m-select__text {
      color: $m-text-light;
    }

    .m-select__arrow {
      fill: $m-text-light;
    }
  }
}
</style>
