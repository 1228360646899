import { Module } from 'vuex-smart-module';
import root from './root';
import lessons from './lessons';

export default new Module({
  modules: {
    root,
    lessons,
  },
});
