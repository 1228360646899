import { defineStore } from 'pinia';
import { api } from '@/services';
import { globalEvents } from '@/services/globalEvents';
import { UserRole } from '@/enums/profile';
import type { LocationQuery } from 'vue-router';

import type {
  Profile, ProfileRole,
  SystemConfig,
} from '@/types';

export type CommonState = {
  systemConfig: SystemConfig;
  profile: Profile | null;
  authRedirectionRoute: string | null;
}

export const useCommonStore = defineStore('common', {
  state: () => <CommonState>({
    systemConfig: null,
    profile: null,
    authRedirectionRoute: null,
  }),
  getters: {
    isEmptyConfig(state) {
      return !state.systemConfig;
    },
    IAMURL(state) {
      return state.systemConfig?.IAM_URL;
    },
    firstName(state) {
      return state.profile?.firstName;
    },
    lastName(state) {
      return state.profile?.lastName;
    },
    patronym(state) {
      return state.profile?.patronym;
    },
    isInnopolisVerificator(state) {
      return !!state.profile?.roles.some((role: ProfileRole) => role.name === UserRole.INNOPOLIS_VERIFICATOR);
    },
  },
  actions: {
    async loadSystemConfig() {
      const { data } = await api.v1.loadSystemConfig();

      this.systemConfig = data;
    },
    async loadProfile() {
      const { data } = await api.v1.loadProfile();

      this.profile = data;
    },
    async logout() {
      const { data } = await api.v1.logout();

      globalEvents.dispatch('logout');

      if (!data?.url) return false;

      window.location.replace(data.url);

      return true;
    },
    async oauth() {
      const { data } = await api.v1.oauth({ fromUrl: window.location.href });

      if (!data?.location) return false;

      window.location.replace(data.location);

      return true;
    },
    async authorize(payload: LocationQuery) {
      try {
        const { data } = await api.v1.authorize(payload);
        this.authRedirectionRoute = data.location;
      } catch {
        this.authRedirectionRoute = '';
      }
    },
  },
});
