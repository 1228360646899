import { Actions } from 'vuex-smart-module';
import rootModule from '../../../root';
import { getResultFromRightAnswers } from './helpers';
import { axios } from '@/services/api';
import {
  isTestAnsweredTrue,
  checkboxOrRadio,
} from '@/components/lessonPage/tests/common/LessonTestTypes/testStates.js';
import type { CorrectionsGetters } from './getters';
import type { CorrectionsMutations } from './mutations';
import type { CorrectionsState } from './state';
import type { StudentTestAnswer } from '../types';
import type { EducationTest, StudentTestResult } from '../types.d';
import type { Store } from 'vuex';
import type { Context } from 'vuex-smart-module';

export class CorrectionsActions extends Actions<
CorrectionsState,
CorrectionsGetters,
CorrectionsMutations,
CorrectionsActions
> {
  rootModule: Context<typeof rootModule> | undefined;
  $init(store: Store<any>) {
    this.rootModule = rootModule.context(store);
  }

  async loadCorrectionsList(groupId: number | null) {
    const { data } = await axios.get(`/correction/group/${groupId}/curriculums`);
    this.commit('SET_CORRECTIONS_LIST', data);
  }
  async loadTestList({ curriculumSubjectId }: {
    curriculumSubjectId: number;
  }) {
    const groupId = this.rootModule?.state.currentStudentGroupId;
    const response = await axios.get(`/correction/group/${groupId}/curriculums/${curriculumSubjectId}/tests`);
    this.commit('SET_TEST_LIST', response.data);
  }
  async loadSubject({ curriculumSubjectId }: {
    curriculumSubjectId: number;
  }) {
    const groupId = this.rootModule?.state.currentStudentGroupId;
    const response = await axios.get(`/correction/group/${groupId}/curriculums/${curriculumSubjectId}`);
    this.commit('SET_SUBJECT', response.data);
  }
  async loadExamList({ lessonId, subjectId }: {
    lessonId: number;
    subjectId: number;
  }) {
    const response = await axios.get(`exam/student/lesson/${lessonId}/exams/${subjectId}/tests`);
    this.commit('SET_TEST_LIST', response.data);
  }
  updateStudentAnswer({ id, value }: { id: number; value: StudentTestAnswer[] }) {
    if (this.state.studentAnswerList[id]) {
      this.commit('UPDATE_TEST_ANSWER', { id, value });
    }
  }
  updateAnswerDisableStatus({ id, disabled }: { id: number; disabled: boolean }) {
    this.commit('UPDATE_TEST_DISABLE', { id, disabled });
  }
  async tryTestAgain({ correctionId, testId }: {
    correctionId: number;
    testId: number;
  }) {
    const { data: studentTestResult } = await axios.post(`/correction/${correctionId}/test-result/reset`);

    this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testId, value: studentTestResult });
    this.commit('UPDATE_STUDENT_LIST_ANSWER', { id: testId });
  }
  async sendTestAnswer({ correctionId, testId, spentTime }: {
    correctionId: number;
    testId: number;
    spentTime: number;
  }) {
    const { answer } = this.state.studentAnswerList[testId];

    const preparedAnswer = answer.map((item) => item.value);
    const data = {
      answer: preparedAnswer,
      spentTime,
    };

    const { data: studentTestResult } = await axios.post(`/correction/${correctionId}/test-result`, data);

    this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testId, value: studentTestResult });
    this.commit('UPDATE_STUDENT_LIST_ANSWER', { id: testId });
  }
  // async showTestAnswer({ correctionId, testId, spentTime }: {
  //   correctionId: number;
  //   testId: number;
  //   spentTime: number;
  // }) {
  //   /*
  //     Костыль для кнопки узнать ответ, при правильном ответе:
  //     Если есть правильный ответ, то мы на клиенте добавляем флаг showSolution: true
  //     для того, чтобы ход решения( поле solution в тесте ) был виден.
  //     То есть смотрим правильный ли ответ, если да, то запроса на сервер не будет.
  //     Запрос для записи в базу - что ответ был просмотрен.
  //   */
  //   const updatedTest = this.getters.currentTestById(testId);
  //   const isTestCorrect = isTestAnsweredTrue(updatedTest);
  //
  //   if (isTestCorrect) {
  //     const updatedStudentTestResult = { ...updatedTest.studentTestResult, showSolution: true };
  //
  //     this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testId, value: updatedStudentTestResult });
  //     return;
  //   }
  //   /* Конец костыля, удалить по возможности */
  //
  //   const { data } = (await axios.get<EducationTest>(`/correction/${correctionId}/test-result/solution`, { params: { spentTime } }));
  //   const studentTestResult: StudentTestResult = data.testAnswers.length && !checkboxOrRadio({ type: data.type })
  //     ? getResultFromRightAnswers(data)
  //     : data.studentTestResult;
  //
  //   const valueToUpdate = studentTestResult || { showSolution: true, result: 'false' };
  //
  //   this.commit('UPDATE_STUDENT_TEST_RESULT', { id: testId, value: valueToUpdate });
  //   this.commit('UPDATE_STUDENT_LIST_ANSWER', { id: testId });
  // }
}
