export enum PropViewValues {
  FILL = 'fill',
  EMPTY = 'empty',
  ICON = 'icon',
  FLAT = 'flat',
  TEXT = 'text',
  BLUE = 'blue',
  BLUE_TEXT = 'blue-text',
  BLUE_EMPTY = 'blue-empty',
  GREEN = 'green',
}

export enum PropSizeValues {
  NORMAL = 'normal',
  SMALL = 'small',
  BIG = 'big',
  CONTENT = 'content',
}
