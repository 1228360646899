class GlobalEvents {
  public subscribe(eventName: string, fn: (value: any) => void) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === eventName) {
        const value = event.newValue !== null ? JSON.parse(event.newValue) : null;
        if (value) return fn(value);
      }
    });
  }

  public dispatch(eventName: string, payload?: any) {
    const value = typeof payload !== 'undefined' ? payload : true;
    window.localStorage.setItem(eventName, JSON.stringify(value));
    window.localStorage.removeItem(eventName);
  }
}

export const globalEvents = new GlobalEvents();
