<template>
  <ul
    class="m-list"
    @scroll="onScroll"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      v-bind="{
        'li-id': item.liId,
        'li-none': item.liNone,
      }"
      class="m-list__item"
      @click="$emit('clickHandler', item)"
    >
      <slot
        :item="item"
        :index="index"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    onScroll: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.m-list {
  background-color: #fff;
  border-radius: 6px;
  &__item {
    position: relative;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &_margins {
    &_xs {
      .m-list__item {
        margin-bottom: 4px;
      }
    }
    &_sm {
      .m-list__item {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
