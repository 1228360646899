export const enum RouteParam {
  AUTH_STATUS = 'authStatus',
  THEME_ID = 'themeId',
  SUBJECT_CLASS_ID = 'subjectClassId',
}

export enum RouteName {
  HOME = 'Home',
  STUDENT_THEME = 'StudentTheme',
  STUDENT_THEME_THEORY = 'StudentThemeTheory',
  STUDENT_THEME_VIDEO = 'StudentThemeVideo',
  STUDENT_THEME_PRACTICE = 'StudentThemePractice',
  STUDENT_THEME_PRACTICE_TASK = 'StudentThemePracticeTask',
  SUBJECT_CLASS = 'SubjectClass',
  OAUTH_RESULT = 'OAuthResult',
  ERROR_403 = 'Error403',
  ERROR_404 = 'Error404',
  ERROR_500 = 'Error500',
}
