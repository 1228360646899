import { Actions } from 'vuex-smart-module';
import { axios } from '@/services/api';
import type { LoadOneLessonPayload, LoadSubjectStatsPayload } from './actions.types';
import type { LessonsGetters } from './getters';
import type { LessonsMutations } from './mutations';
import type { LessonsState } from './state';

export class LessonsActions extends Actions<
LessonsState,
LessonsGetters,
LessonsMutations,
LessonsActions
> {
  async loadOneLesson({ groupId, lessonId }: LoadOneLessonPayload) {
    const res = await axios.get(`/schedule/student/schedule/groups/${groupId}/lessons/${lessonId}`);

    this.commit('setItem', {
      item: 'currentLesson',
      value: res.data,
    });

    return res;
  }
  async loadSubjectStats({ curriculumSubjectId }: LoadSubjectStatsPayload) {
    return axios.get(`/content/curriculumsubject/${curriculumSubjectId}/lesson-subjects-stats`);
  }
}
