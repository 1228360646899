import { RouteName, RouteParam } from '@/enums';

import type { RouteRecordRaw } from 'vue-router';

const _subjectClass = () => import(
  /* webpackChunkName: "subject-class" */
  '@/views/SubjectClass.vue'
);

export const subjectClassRoutes: RouteRecordRaw[] = [
  {
    path: `/subjects-classes/:${RouteParam.SUBJECT_CLASS_ID}(\\d+)`,
    name: RouteName.SUBJECT_CLASS,
    component: _subjectClass,
  },
];
