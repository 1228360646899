<template>
  <textarea
    ref="input"
    class="m-input-new mx-input__textarea custom-scroll-v1"
    :class="computedClasses"
    :rows="rows"
    v-bind="$attrs"
    :style="errorStyle"
    @input="onInput"
    :value="modelValue"
  ></textarea>
</template>

<script>
import './MTextarea.scss';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: [ String, Number ],
      default: '',
    },
    rows: {
      type: [Number, String],
      default: '10',
      validator: v => !Number.isNaN(parseFloat(v)),
    },
    rowHeight: {
      type: [Number, String],
      default: 12,
      validator: v => !Number.isNaN(parseFloat(v)),
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: Boolean,
      default: true,
    },
    state: {
      type: String,
      default: null,
      validator (value) {
        return ['success', 'error'].includes(value);
      },
    },
    customError: {
      type: [String, Object],
      default: null,
    },
    errorStyle: [Object, String],
    dataTestId: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      isActive: false,
      defaultError: null,
      failedRules: null,
      inputState: null,
    };
  },
  computed: {
    computedClasses () {
      return {
        [`mx-input_state_${this.state}`]: this.state,
        'mx-input_resizable': this.resizable,
        'mx-input_autosize': this.autosize,
        // TODO ок замена или нет?
        // ...MInput.computed.computedClasses.call(this),
        // ...MInput.options.computed.computedClasses.call(this),
      };
    },
    errorMsg() {
      if (this.customError) {
        if (typeof this.customError === 'string') {
          return this.customError;
        }
        if (typeof this.customError === 'object') {
          const mergedErrors = { ...this.failedRules, ...this.customError };
          return Object.values(mergedErrors)[0];
        }
      }
      return this.defaultError;
    },
    isErrorVisible() {
      return this.defaultError && !this.isActive;
    },
  },
  mounted () {
    if (this.autosize) {
      this.calculateAutosize();
    }
  },
  methods: {
    calculateAutosize () {
      const textarea = this.$refs.input;
      if (!textarea) return;
      textarea.style.height = '0';
      const height = textarea.scrollHeight;
      const minHeight = Number(this.rows) * Number(this.rowHeight);
      textarea.style.height = `${Math.max(minHeight, height)}px`;
    },
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  }
});
</script>

<style lang="scss" scoped>
textarea {
  overflow: hidden;
  padding: 6px 10px;
}

.mx-input {
  position: relative;

  &__error {
    display: inline-block;
    min-width: 364px;
    position: absolute;
    bottom: calc(100% + 6px);
    right: 0;
    padding: 7px 12px;
    border: 1px solid $m-light-gray;
    border-radius: 6px;
    font-size: 11px;
    line-height: 13px;
    color: $m-light-red;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .1);
    background-color: #fff;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -6px;
      left: 50%;
      margin-right: -6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #fff transparent transparent transparent;
    }
  }

  &__cross {
    position: absolute;
    bottom: 10px;
    right: 12px;
    width: 12px !important;
    height: 12px !important;
    fill: $m-light-red !important;
  }

  &_state {
    &_success {
      background-color: rgba(164, 209, 108, 0.5);
      color: #000;
    }

    &_error {
      background-color: rgba(239, 119, 122, 0.5);
      color: #000;
    }
  }
}

</style>
