<template>
  <div
    :class="avatarClasses"
    class="m-avatar"
  >
    <div class="m-avatar__wrapper">
      <img
        v-if="userInfo.avatarUrl"
        :src="userInfo.avatarUrl"
        :alt="shortName"
      >
      <span v-else-if="shortName">
        {{ shortName }}
      </span>
      <SvgIcon
        v-else
        data="@icons/LMSIcons/profile.svg"
        class="m-avatar__icon"
      />
    </div>
    <span
      v-if="plankText"
      class="m-avatar__plank"
    >
      {{ plankText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'MAvatar',
  props: {
    userInfo: {
      type: Object,
      default: () => ({
        avatarUrl: null,
        firstName: '',
        lastName: '',
      }),
    },
    size: {
      type: String,
      default: 'md',
      validate(value) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
      },
    },
    plankText: {
      type: String,
      default: null,
    },
  },
  computed: {
    shortName() {
      // Добавляем первую букву имени
      let result = this.userInfo.firstName && this.userInfo.firstName.length ? this.userInfo.firstName[0] : '';
      // Если есть имя, то цепляем к нему первую букву фамилии
      result += result.length && this.userInfo.lastName && this.userInfo.lastName.length ? this.userInfo.lastName[0] : '';
      // Если у юзера нет имени, берем первую букву почты

      return result.length > 1 ? result.toUpperCase() : '';
    },
    avatarClasses() {
      return `m-avatar_size_${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .m-avatar {
    color: #fff;
    position: relative;
    &__icon {
      fill: #fff;
      width: 40%;
      height: 40%;
    }
    &__wrapper {
      background-color: $color-main;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 300;
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__plank {
      font-family: "WorkSans", sans-serif;
      font-weight: 500;
      font-size: 11px;
      border: 1px solid #fff;
      padding: 1px 3px;
      border-radius: 8px;
      position: absolute;
      left: 80%;
      top: -2px;
      height: 16px;
      min-width: 16px;
    }
    &_size {
      &_xs {
        .m-avatar__wrapper {
          width: 24px;
          height: 24px;
          font-size: 11px;
        }
      }
      &_sm {
        .m-avatar__wrapper {
          width: 36px;
          height: 36px;
          font-size: 14px;
        }
      }
      &_md {
        .m-avatar__wrapper {
          width: 48px;
          height: 48px;
          font-size: 16px;
        }
      }
      &_lg {
        .m-avatar__wrapper {
          width: 97px;
          height: 97px;
          font-size: 32px;
        }
      }
      &_xl {
        .m-avatar__wrapper {
          width: 110px;
          height: 110px;
          font-size: 32px;
        }
      }
    }
  }
</style>
