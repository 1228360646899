import cloneDeep from 'lodash/cloneDeep';
import {
  testResults,
  answerValueAsId,
  checkboxOrRadio,
} from '@/components/lessonPage/tests/common/LessonTestTypes/testStates.js';

//TODO почему testResult - undefined?
export const getResultFromRightAnswers = ({ testRightAnswers, type, testResult }: any) => {
  const answer = testRightAnswers.map((item: any) => {
    const value = answerValueAsId({ type })
      ? String(item.id)
      : item.variants[0];
    return { value, result: true };
  });

  return {
    ...(
      testResult
      || { showSolution: true, result: testResults.false } // Для отображения хода решения в модуле препода
    ),
    answer,
  };
};

export const getAnswerListItem = (testResult: any) => {
  // answer: массив ответов в ручную: радио, чекбоксы, тексты и тд
  // attaches: массив прикрепленных файлов
  // disabled: статус кнопки отправить см: getTestDisableStatus
  const answerItem: any = { answer: [], attaches: [], disabled: true };

  if (testResult) {
    if (testResult?.answer?.length) {
      answerItem.answer = cloneDeep(testResult.answer);
      answerItem.oldAnswer = cloneDeep(testResult.answer);
    }

    if (testResult?.attaches?.length) {
      answerItem.attaches = cloneDeep(testResult.attaches);
      answerItem.oldAttaches = cloneDeep(testResult.attaches);
    }
  }

  return answerItem;
};

// TODO тут обрезаются поля, зачем?
export const mapTestToAnswerList = (list: any) => {
  // list.reduce((resObj: any, { id, educationTestRightAnswers, testResult, type }: any) => {
  return list.reduce((resObj: any, { id, testRightAnswers, testResult, type }: any) => {
    resObj[id] = testRightAnswers?.length && !checkboxOrRadio({ type })
      ? getResultFromRightAnswers({ testRightAnswers, type })
      : getAnswerListItem(testResult);

  return resObj;
  }, {});
}
