export interface StudentAnswerList {
  [key: number]: {
    answer: Array<any>;
    attaches: Array<any>;
    disabled: boolean;
    oldAnswer: Array<any>;
  };
}

export enum ExamStatus {
  cantStart = 'cant_start',
  notStarted = 'not_started',
  inProgress = 'in_progress',
  notChecked = 'not_checked',
  checked = 'checked',
  notSent = 'not_sent',
  sent = 'sent',
}

export interface ActiveInfoImage {
  picturePath: string;
  picturePathPhone: string;
  picturePathTablet: string;
  title: string;
  url: string;
}
