
import { defineComponent } from 'vue';
import { stateTypes, viewTypes } from '../enums';
import { emitter } from '@/utils/emitter';
import type { NotifyParams } from '../types';

export default defineComponent({
  name: 'MNotify',
  components: {
    NotifyItem: () => import('./NotifyItem.vue'),
  },
  props: {
    delay: {
      type: Number,
      default: 3000,
      validator(val: number) {
        return val >= 0;
      },
    },
    speed: {
      type: Number,
      default: 300,
    },
    maxLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    const list: any[] = [];

    return {
      list,
      idCount: 0,
    };
  },
  computed: {
    activeItems() {
      return this.list.filter((item: NotifyParams) => item.state !== 'destroyed');
    },
    totalDelay() {
      return this.delay + 2 * this.speed;
    },
  },
  mounted() {
    emitter.on('add', () => this.addItem());
  },
  methods: {
    addItem(params: NotifyParams = {}) {
      const item: NotifyParams = {
        id: this.idCount,
        title: 'Unknown error',
        text: 'Something went wrong',
        state: stateTypes.ACTIVE,
        view: viewTypes.ERROR,
        ...params,
      };

      // TODO: Разобраться с типом
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      item.timer = setTimeout(() => {
        this.destroy(item);
      }, item.delay || this.totalDelay);

      this.idCount += 1;

      this.list.unshift(item);

      if (this.activeItems.length > this.maxLength) {
        this.destroy(this.activeItems[this.maxLength]);
      }
    },
    destroy(item: NotifyParams) {
      clearTimeout(item.timer);
      item.state = stateTypes.DESTROYED;
    },
  },
});
