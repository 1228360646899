import isFunction from 'lodash/isFunction';

// TODO типизировать. Было скопировано из LMS как есть.
export default {
  beforeMount(el, binding) {
    const { bubble } = binding.modifiers;
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        try {
          if (isFunction(binding.value)) {
            binding.value(e);
          } else {
            binding.value.handler(e);
          }
        } catch (error) {
          console.log(el, e, binding);
        }
      }
    };
    el.__vueClickOutside__ = handler;

    document.addEventListener('click', handler);
  },
  unmounted(el) {
    document.removeEventListener('click', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
};
