import { errorsRoutes } from '@/router/routes/errors';
import { authRoutes } from '@/router/routes/oauth';
import { studentThemeRoutes } from '@/router/routes/theme';
import { subjectClassRoutes } from '@/router/routes/subject-class';

import { RouteName } from '@/enums';

import type { RouteRecordRaw } from 'vue-router';

const _materialLibrary = () => import(
  /* webpackChunkName: "material-library" */
  '@/views/MaterialLibrary.vue'
);

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteName.HOME,
    component: _materialLibrary,
  },
  ...studentThemeRoutes,
  ...subjectClassRoutes,
  ...errorsRoutes,
  ...authRoutes,
];

export default routes;
