import { ExamStatus } from './state.types';
import type { StudentAnswerList } from './state.types';

export class ExamState {
  testList: any[] = [];
  examStats: any = {};
  examsInfo: any = {};
  studentAnswerList: StudentAnswerList = {};
  currentTestAnswerLocal: any = {};
  EXAM_STATUS = {
    cantStart: ExamStatus.cantStart,
    notStarted: ExamStatus.notStarted,
    inProgress: ExamStatus.inProgress,
    notChecked: ExamStatus.notChecked,
    checked: ExamStatus.checked,
    notSent: ExamStatus.notSent,
    sent: ExamStatus.sent,
  };
}
